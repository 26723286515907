import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { RootState } from 'app/providers/StoreProvider/config/rootReducer'

export const updateAva = createAsyncThunk<any, { file: File }, { state: RootState }>(
    'user/updateAva',
    async ({ file }, thunkAPI) => {
        const formData = new FormData()
        formData.append('input_ava', file)

        const { user } = thunkAPI.getState()
        const token = localStorage.getItem('token')
        try {
            const response = await axios.post('https://api.yourtunes.net/api/v2/user/update_ava', formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            return response.data.result
        } catch (e) {
            console.log(e)
        }
    },
)
