import { FC, useEffect } from 'react'
import { DatePickerInput } from '@mantine/dates'
import dayjs from 'dayjs'
import { IconCalendar } from '@tabler/icons-react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'shared/hooks/useAppDispatch/useAppDispatch'
import { getPublicDate } from '../../models/selectors/getPublicDate'
import { getActualDate } from '../../models/selectors/getActualDate'
import { updateDateActions } from '../../models/slices/updateDateSlice'

interface InputActualDateProps {}

export const InputActualDate: FC<InputActualDateProps> = (props) => {
    const {} = props

    const dispatch = useAppDispatch()

    const publicDate = useSelector(getPublicDate)
    const _actualDate = useSelector(getActualDate)

    const actualDate = !_actualDate ? null : dayjs(_actualDate).toDate()

    const updateActualDate = (value: Date | null) => {
        dispatch(updateDateActions.reducerActualDate(!value ? null : dayjs(value).format('YYYY-MM-DD')))
    }

    useEffect(() => {
        if (dayjs(publicDate).isBefore(actualDate)) {
            dispatch(updateDateActions.reducerActualDate(publicDate))
        }
    }, [publicDate, dispatch])

    useEffect(() => {
        updateActualDate(null)
    }, [])

    return (
        <DatePickerInput
            value={actualDate}
            onChange={updateActualDate}
            label="Фактическая дата релиза"
            placeholder="Выберите дату"
            maw={200}
            mb="md"
            valueFormat="DD.MM.YYYY"
            maxDate={dayjs(publicDate).toDate()}
            clearable
            icon={<IconCalendar size="1.1rem" stroke={1.5} />}
        />
    )
}
