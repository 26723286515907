import { Button, Skeleton } from '@mantine/core'
import { useGetBankCards } from 'features/fastPaymentCards/api/fastPaymentCardsApi'
import { getCardUid } from 'features/fastPaymentCards/model/selectors/getCardUid'
import {
    usePaySubscription,
    usePayFastSubscription,
    usePayQuickRelease,
    usePayFastQuickRelease,
} from 'features/paymentGateway/api/paymentApi'
import { getPaymentAcceptOffer } from 'features/paymentGateway/model/selectors/getPaymentAcceptOffer'
import { getPaymentPayFromBalance } from 'features/paymentGateway/model/selectors/getPaymentPayFromBalance'
import { getPaymentPrice } from 'features/paymentGateway/model/selectors/getPaymentPrice'
import { paymentActions } from 'features/paymentGateway/model/slices/paymentSlice'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'shared/hooks/useAppDispatch/useAppDispatch'
import { useGlobalAlertModal } from 'widgets/GlobalAlertModal'
import { getPaymentType } from '../../model/selectors/getPaymentType'
import { getPaymentAdditionalOption } from '../../model/selectors/getPaymentAdditionalOption'

interface PaymentButtonProps {
    buttonType: 'alt' | 'main'
}

export const PaymentButton: FC<PaymentButtonProps> = (props) => {
    const { buttonType } = props

    const { t } = useTranslation()

    const dispatch = useAppDispatch()

    const payFromBalance = useSelector(getPaymentPayFromBalance)
    const acceptOffer = useSelector(getPaymentAcceptOffer)
    const cardUid = useSelector(getCardUid)
    const price = useSelector(getPaymentPrice)
    const paymentType = useSelector(getPaymentType)
    const additionalOption = useSelector(getPaymentAdditionalOption)

    const { isLoading: isLoadingGetBankCards } = useGetBankCards()

    const [payFastSubscription] = usePayFastSubscription()
    const [paySubscription] = usePaySubscription()
    const [payQuickRelease] = usePayQuickRelease()
    const [payFastQuickRelease] = usePayFastQuickRelease()
    const { openGlobalAlert } = useGlobalAlertModal()

    if (isLoadingGetBankCards) {
        return <Skeleton h={30} width={'100%'} />
    }

    console.log('paymentType', paymentType)

    if (buttonType === 'alt') {
        const handlePayAlt = async () => {
            if (paymentType === 'subscription') {
                try {
                    const response = await paySubscription({ balance: false, alt: true }).unwrap()

                    if (response.status === 'fail') {
                        openGlobalAlert({
                            message: response.message ?? 'Произошла непредвиденная ошибка оплаты',
                            title: 'Ошибка оплаты!',
                            type: 'error',
                        })
                        return
                    }

                    if (response.result.link !== 'https://yourtunes.net/lk-profile#pay-success') {
                        window.location.href = response.result.link
                    }
                } catch (e: any) {
                    openGlobalAlert({
                        message: e.data.message ?? 'Произошла непредвиденная ошибка оплаты',
                        title: 'Ошибка оплаты!',
                        type: 'error',
                    })
                    dispatch(paymentActions.reducerPaymentModalClose())
                }
            }
            if (paymentType === 'quickRelease') {
                try {
                    const response = await payQuickRelease({
                        uid: additionalOption?.releaseUid ?? '',
                        balance: false,
                        alt: true,
                    }).unwrap()

                    if (response.status === 'fail') {
                        openGlobalAlert({
                            message: response.message ?? 'Произошла непредвиденная ошибка оплаты',
                            title: 'Ошибка оплаты!',
                            type: 'error',
                        })
                        return
                    }

                    if (response.result.link !== 'https://yourtunes.net/lk-profile#pay-success') {
                        window.location.href = response.result.link
                    }
                } catch (e: any) {
                    openGlobalAlert({
                        message: e.data.message ?? 'Произошла непредвиденная ошибка оплаты',
                        title: 'Ошибка оплаты!',
                        type: 'error',
                    })
                    dispatch(paymentActions.reducerPaymentModalClose())
                }
            }
        }

        return (
            <Button color="dark" onClick={handlePayAlt} disabled={!acceptOffer}>
                Оплатить с карты
            </Button>
        )
    }

    if (buttonType === 'main') {
        const handlePayMain = async () => {
            try {
                if (cardUid === '') {
                    if (paymentType === 'subscription') {
                        const response = await paySubscription({ balance: payFromBalance }).unwrap()

                        if (response.status === 'fail') {
                            openGlobalAlert({
                                message: response.message ?? 'Произошла непредвиденная ошибка оплаты',
                                title: 'Ошибка оплаты!',
                                type: 'error',
                            })
                            return
                        }

                        if (response.result.link !== 'https://yourtunes.net/lk-profile#pay-success') {
                            window.location.href = response.result.link
                        }

                        if (payFromBalance) {
                            openGlobalAlert({
                                message: 'Вы оплатили с баланса',
                                title: 'Успешная оплата!',
                                type: 'success',
                            })
                        }

                        dispatch(paymentActions.reducerPaymentModalClose())
                    }

                    if (paymentType === 'quickRelease') {
                        const response = await payQuickRelease({
                            uid: additionalOption?.releaseUid ?? '',
                            balance: payFromBalance,
                        }).unwrap()

                        if (response.status === 'fail') {
                            openGlobalAlert({
                                message: response.message ?? 'Произошла непредвиденная ошибка оплаты',
                                title: 'Ошибка оплаты!',
                                type: 'error',
                            })
                            return
                        }
                        if (
                            response.result.link !==
                            'https://yourtunes.net/dobavit-reliz-step-3?uid=6513f03e-45fa-7565-5945-8d379169c135#pay-success'
                        ) {
                            window.location.href = response.result.link
                        }

                        if (payFromBalance) {
                            openGlobalAlert({
                                message: 'Вы оплатили с баланса',
                                title: 'Успешная оплата!',
                                type: 'success',
                            })
                        }

                        dispatch(paymentActions.reducerPaymentModalClose())
                    }
                } else {
                    if (paymentType === 'subscription') {
                        const response = await payFastSubscription({
                            balance: payFromBalance,
                            card_uid: cardUid,
                        }).unwrap()
                        if (response.status === 'fail') {
                            openGlobalAlert({
                                message: response.message ?? 'Произошла непредвиденная ошибка оплаты',
                                title: 'Ошибка оплаты!',
                                type: 'error',
                            })
                            return
                        }

                        if (payFromBalance) {
                            openGlobalAlert({
                                message: `Вы оплатили с баланса ${price.name} руб.`,
                                title: 'Успешная оплата!',
                                type: 'success',
                            })
                        } else {
                            openGlobalAlert({
                                message: `Вы оплатили с карты ${price.name} руб.`,
                                title: 'Успешная оплата!',
                                type: 'success',
                            })
                        }

                        dispatch(paymentActions.reducerPaymentModalClose())
                    }

                    if (paymentType === 'quickRelease') {
                        const response = await payFastQuickRelease({
                            balance: payFromBalance,
                            card_uid: cardUid,
                            uid: additionalOption?.releaseUid ?? '',
                        }).unwrap()

                        if (response.status === 'fail') {
                            openGlobalAlert({
                                message: response.message ?? 'Произошла непредвиденная ошибка оплаты',
                                title: 'Ошибка оплаты!',
                                type: 'error',
                            })
                            return
                        }

                        if (payFromBalance) {
                            openGlobalAlert({
                                message: `Вы оплатили с баланса ${price.name} руб.`,
                                title: 'Успешная оплата!',
                                type: 'success',
                            })
                        } else {
                            openGlobalAlert({
                                message: `Вы оплатили с карты ${price.name} руб.`,
                                title: 'Успешная оплата!',
                                type: 'success',
                            })
                        }

                        dispatch(paymentActions.reducerPaymentModalClose())
                    }
                }
            } catch (e: any) {
                openGlobalAlert({
                    message: e.data.message ?? 'Произошла непредвиденная ошибка оплаты',
                    title: 'Ошибка оплаты!',
                    type: 'error',
                })
                dispatch(paymentActions.reducerPaymentModalClose())
            }
        }

        return (
            <Button color="dark" onClick={handlePayMain} disabled={!acceptOffer}>
                {payFromBalance ? 'Оплатить с баланса' : cardUid !== '' ? 'Оплатить с карты' : 'Оплатить с новой карты'}
            </Button>
        )
    }

    return null
}
