import { createStyles } from '@mantine/core'

export const useStyles = createStyles((theme) => ({
    menuItem: {
        display: 'flex',
        alignItems: 'center',
        margin: '27px 0',
        '&:hover': {
            color: theme.colors.primary[5],
        },
        '&:hover > svg': {
            fill: theme.colors.primary[5],
        },
        color: 'inherit',
    },
    icon: {
        marginRight: theme.spacing.lg,
        fill: theme.colors.gray[0],
    },
    active: {
        color: theme.colors.primary[4],
    },
    activeIcon: {
        fill: theme.colors.primary[4],
    },
}))
