import { createStyles } from '@mantine/core'

export const useStyles = createStyles((theme) => ({
    releaseTrackList: {},
    trackItem: {
        paddingLeft: 20,
        paddingRight: 0,
        paddingTop: 0,
        paddingBottom: 0,
        marginBottom: 40,
        borderRadius: 18,
        borderWidth: 1,
        borderColor: theme.colors.gray[3],
        borderStyle: 'solid',
        boxShadow: '6px 7px 9px 4px rgba(34, 60, 80, 0.2)',
    },
    rightSection: {
        borderBottomRightRadius: 18,
        borderTopRightRadius: 18,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0 15px',
        borderLeftWidth: 1,
        borderLeftColor: theme.colors.gray[3],
        borderLeftStyle: 'solid',
        height: '100%',
        minHeight: 70,
    },
}))
