import axios from 'axios'

export const fetchUserToken = async function () {
    try {
        // Сначала проверим, аутентифицирован ли пользователь через WP
        const tarifResponse = await axios.get('https://yourtunes.net/?yt-ajax=get_my_tarif')
        const tarifData = tarifResponse.data

        if (!tarifData || tarifData.error) {
            throw new Error('Неаутентифицированный пользователь или ошибка на WP')
        }

        // Если пользователь аутентифицирован, пробуем получить токен
        const tokenResponse = await axios.get('/?yt-api-v2-token')
        const tokenData = tokenResponse.data

        if (tokenData.status !== 'ok') {
            throw new Error('Ошибка при получении токена')
        }

        window.localStorage.setItem('token', tokenData.result)
        window.localStorage.setItem('token_date', new Date().getTime().toString())

        return tokenData.result
    } catch (error) {
        console.log('Ошибка при проверке аутентификации:', error)
        throw error
    }
}
