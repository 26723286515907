import { FC } from 'react'
import { Group, Modal, Skeleton } from '@mantine/core'
import { NavigationButtons } from 'shared/ui/NavigationButtons/NavigationButtons'
import { VerificationForm } from 'features/verificationUser'
import { useDisclosure } from '@mantine/hooks'
import { useGetUserSubscription } from 'entities/Subscription/api/subscriptionApi'
import { useNavigtaion } from 'shared/hooks/useNavigation/useNavigation'
import { useCurrentReleaseUid } from 'entities/Release'
import { RoutePath } from 'shared/configs/routeConfig/routeConfig'
import { useGetUserInfo } from 'entities/User/api/userApi'
import { useSelector } from 'react-redux'
import { useGlobalAlertModal } from 'widgets/GlobalAlertModal'
import { useModalInUpdateDate } from '../../models/hooks/useModalInUpdateDate'
import { useConfirmStep3, useUpdateDateRelease } from '../../api/updateDateApi'
import { UpdateDateRequest } from '../../models/types/updateDate'
import { getPublicDate } from '../../models/selectors/getPublicDate'
import { getActualDate } from '../../models/selectors/getActualDate'
import { getIsConfirmButton } from '../../models/selectors/getIsConfirmButton'

interface ConfirmedUpdateDateButtonProps {}

export const ConfirmedUpdateDateButton: FC<ConfirmedUpdateDateButtonProps> = (props) => {
    const {} = props

    const [openedVerification, { close: closeVerification, open: openVerification }] = useDisclosure()
    const releaseUID = useCurrentReleaseUid()

    const { data: subscriptionData, isLoading: isLoadingSubscription } = useGetUserSubscription()
    const { data: userData, isLoading: isLoadingUserInfo } = useGetUserInfo()
    const [updateDateRelease, { isLoading: isLoadingUpdateRelease }] = useUpdateDateRelease()
    const [confirmStep3, { isLoading: isLoadingConfirm }] = useConfirmStep3()

    const handleBack = useNavigtaion(RoutePath.adding_release_step2, releaseUID)
    const handleNext = useNavigtaion(RoutePath.adding_release_finish, releaseUID)

    const publicDate = useSelector(getPublicDate)
    const actualDate = useSelector(getActualDate)
    const isConfirmButtonDisabled = useSelector(getIsConfirmButton)

    const { openTariffModal, openPaymentSubscription } = useModalInUpdateDate(subscriptionData ?? null)
    const { openGlobalAlert } = useGlobalAlertModal()

    if (isLoadingSubscription || isLoadingUserInfo) {
        return (
            <Group position={'right'}>
                <Skeleton width={'60%'} height={60} />
            </Group>
        )
    }

    if (subscriptionData && userData) {
        const handleUpdateDateInfoRelease = async () => {
            //Если верификация не заполнена, запрашиваем её
            if (userData.result.verification_data === null) {
                openVerification()
                return
            }

            //Проверка на подписку
            if (!subscriptionData.tarif) {
                openTariffModal()
                return
            }

            //Если подписка не оплачена, просим её оплату
            if (!subscriptionData.is_pay) {
                openPaymentSubscription()
                return
            }

            try {
                const response = await confirmStep3({ uid: releaseUID }).unwrap()
                if (response.status !== 'ok') {
                    openGlobalAlert({ message: response.message, title: 'Ошибка!', type: 'error' })
                    return
                }
                const sendData: UpdateDateRequest = {
                    uid: releaseUID,
                    public_date: publicDate,
                    actual_date: !actualDate ? publicDate : actualDate,
                }

                try {
                    await updateDateRelease(sendData).unwrap()
                    handleNext()
                } catch (e: any) {
                    openGlobalAlert({ message: e.data.message, title: 'Ошибка!', type: 'error' })
                }
            } catch (e: any) {
                openGlobalAlert({ message: e.data.message, title: 'Ошибка!', type: 'error' })
            }
        }

        return (
            <>
                <NavigationButtons
                    onPrev={handleBack}
                    onNext={handleUpdateDateInfoRelease}
                    isLoading={isLoadingUpdateRelease || isLoadingConfirm}
                    textNext="Далее"
                    textPrev="Назад"
                    isNextDisabled={isConfirmButtonDisabled}
                />
                <Modal opened={openedVerification} onClose={closeVerification} title="Верификация" size="lg">
                    <VerificationForm onClose={closeVerification} />
                </Modal>
            </>
        )
    }
    return null
}
