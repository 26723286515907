import { createStyles } from '@mantine/core'

export const useStyles = createStyles((theme) => ({
    releaseCard: {
        overflow: 'visible',
    },
    draft: { color: theme.colors.red[6] },
    ready: { color: theme.colors.green },
    error: { color: theme.colors.red[9] },
    published: { color: theme.colors.lime },
    moderation: { color: theme.colors.orange },
    review: { color: theme.colors.yellow },
    deleted: { color: theme.colors.gray },
    rejected: { color: theme.colors.red },
}))
