import { FC, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Alert, Button, Center, Flex, Grid, Radio, Skeleton } from '@mantine/core'
import { getYearlyTariffInfo } from 'features/selectTariff/model/utils/getYearlyTariffInfo'
import { useGetUserSubscription } from 'entities/Subscription/api/subscriptionApi'
import { useSubscriptionStatus } from 'entities/Subscription/hooks/useSubscriptionStatus'
import { usePaymentStatus } from 'entities/Subscription/hooks/usePaymentStatus'
import { useAppDispatch } from 'shared/hooks/useAppDispatch/useAppDispatch'
import { selectTariffActions } from 'features/selectTariff/model/slices/selectTariffSlice'
import { paymentActions } from 'features/paymentGateway/model/slices/paymentSlice'
import { useSelector } from 'react-redux'
import { IconInfoSquareFilled } from '@tabler/icons-react'
import { useGlobalAlertModal } from 'widgets/GlobalAlertModal'
import { getTariffMessage } from '../../model/selectors/getTariffMessage'
import { useGetTariffList, useSetTariff } from '../../api/selectTariffApi'
import { useStyles } from './TariffSelector.styles'
import { TariffItemCard } from '../TariffItemCard/TariffItemCard'
import { getSelectActivityTariff } from '../../model/selectors/getSelectActivityTariff'

interface TariffSelectorProps {
    className?: string
}

export const TariffSelector: FC<TariffSelectorProps> = (props) => {
    const { className } = props

    const { classes } = useStyles()
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const [selectedTariff, setSelectedTariff] = useState<string | undefined>()
    const { data: subscriptionData, isLoading: isLoadingSubscription } = useGetUserSubscription()
    // const [getTariffList, { data: tariffListData, isLoading: isLoadingTariffList }] = useLazyGetTariffList()
    const { data: tariffListData, isLoading: isLoadingTariffList } = useGetTariffList()
    const [setTariff, { isLoading: isLoadingSetTariff }] = useSetTariff()

    const { openGlobalAlert, closeGlobalAlert } = useGlobalAlertModal()

    const message = useSelector(getTariffMessage)
    const selectActivity = useSelector(getSelectActivityTariff)

    const subscription = useSubscriptionStatus({
        next_tarif: subscriptionData?.next_tarif ?? null,
        tarif: subscriptionData?.tarif ?? null,
    })

    const payment = usePaymentStatus({
        is_pay: subscriptionData?.is_pay ?? null,
        next_is_pay: subscriptionData?.next_is_pay ?? null,
    })

    const openPaymentModal = (
        tariffName: string,
        tariffPrice: string,
        tariffDateStart: string,
        tariffDateEnd: string,
    ) => {
        dispatch(
            paymentActions.reducerPaymentModalOpen({
                isPaymentModalOpen: true,
                period: {
                    title: 'Действителен:',
                    name: `c ${tariffDateStart} по ${tariffDateEnd}`,
                },
                price: { title: 'Стоимость тарифа:', name: tariffPrice },
                product: { title: 'Название тарифа:', name: tariffName },
                type: 'subscription',
            }),
        )
    }

    const closeTariffModal = () => {
        dispatch(selectTariffActions.reducerTariffModal({ isOpen: false, message: '', startIndex: 0 }))
    }

    useEffect(() => {
        if (selectActivity.startIndex !== 0) {
            //Делаем эту проверку для того, чтобы если ограниченное количество тарифов не было выбора по умолчанию
            setSelectedTariff(undefined)
        } else {
            if (subscriptionData)
                setSelectedTariff(subscriptionData.tarif ? subscriptionData.tarif.toString() : undefined)
        }
    }, [subscriptionData])

    if (isLoadingTariffList || isLoadingSubscription) {
        return (
            <Grid>
                <Grid.Col md={4}>
                    <Skeleton width={'100%'} height={300} />
                </Grid.Col>
                <Grid.Col md={4}>
                    <Skeleton width={'100%'} height={300} />
                </Grid.Col>
                <Grid.Col md={4}>
                    <Skeleton width={'100%'} height={300} />
                </Grid.Col>
                <Grid.Col mt={40}>
                    <Center>
                        <Skeleton width={'70%'} height={40} />
                    </Center>
                </Grid.Col>
            </Grid>
        )
    }

    const onCloseAlert = () => {
        dispatch(selectTariffActions.reducerTariffMessage(''))
    }

    if (tariffListData && subscriptionData) {
        const handleSetTariff = async () => {
            // Условие если тариф не изменяется, но открываем оплату
            if (
                subscription === 'subscribed' &&
                payment === 'noPayment' &&
                Number(selectedTariff) === subscriptionData.tarif
            ) {
                closeTariffModal()
                openPaymentModal(
                    subscriptionData.tarif_name ?? '',
                    subscriptionData.price?.toString() ?? '',
                    subscriptionData.date_start ?? '',
                    subscriptionData.date_end ?? '',
                )
                return
            }

            try {
                const response = await setTariff({ tarif: Number(selectedTariff) }).unwrap()

                if (response.status === 'fail') {
                    //Выводим ошибку
                    openGlobalAlert({
                        message:
                            response.message || 'Неизвестная ошибка, повторите позже или обратитесь в тех.поддержку',
                        title: 'Ошибка',
                        type: 'error',
                    })
                    //Закрываем модальное окно тарифов
                    closeTariffModal()
                    return
                }
                console.log('смена тарифа', response)

                // Закрываем модальное окно тарифов и открываем модальное окно оплаты
                closeTariffModal()
                openPaymentModal(response.tarif_name, response.price.toString(), response.date_start, response.date_end)
            } catch (e) {}
        }

        return (
            <>
                {!!message && (
                    <Alert
                        title="Внимание!"
                        icon={<IconInfoSquareFilled />}
                        color="blue"
                        withCloseButton
                        onClose={onCloseAlert}
                        mb={30}
                    >
                        {message}
                    </Alert>
                )}
                <Radio.Group value={selectedTariff} onChange={setSelectedTariff} name="tariffId">
                    <Grid justify="center">
                        {tariffListData.result
                            .slice(selectActivity.startIndex, selectActivity.endIndex)
                            .map((tariff) => {
                                const yearlyTariffInfo = getYearlyTariffInfo(tariffListData.result, tariff)

                                return (
                                    <Grid.Col md={4} key={tariff.id} pos={'relative'} pb={150}>
                                        <TariffItemCard tariff={tariff} />
                                        <Flex
                                            pos={'absolute'}
                                            direction="column"
                                            justify={'center'}
                                            align={'flex-start'}
                                            mt={30}
                                            pl={15}
                                        >
                                            <Radio
                                                value={tariff.id.toString()}
                                                label={`${tariff.name} - ${tariff.price} руб.`}
                                                mb={25}
                                            />
                                            {yearlyTariffInfo && (
                                                <Radio
                                                    value={yearlyTariffInfo.id}
                                                    label={`${yearlyTariffInfo.name} - ${yearlyTariffInfo.price} руб.`}
                                                />
                                            )}
                                        </Flex>
                                    </Grid.Col>
                                )
                            })}
                    </Grid>
                </Radio.Group>
                <Center mb={15}>
                    <Button
                        color="dark"
                        maw={500}
                        fullWidth
                        onClick={handleSetTariff}
                        loading={isLoadingSetTariff}
                        disabled={
                            !selectedTariff || (payment === 'paid' && Number(selectedTariff) === subscriptionData.tarif)
                        }
                    >
                        {subscription === 'noSubscription' && 'Выбрать тариф'}
                        {subscription === 'subscribed' &&
                            Number(selectedTariff) !== subscriptionData.tarif &&
                            'Изменить тариф'}
                        {subscription === 'subscribed' &&
                            payment === 'paid' &&
                            Number(selectedTariff) === subscriptionData.tarif &&
                            'Тариф оплачен'}
                        {subscription === 'subscribed' &&
                            payment === 'noPayment' &&
                            Number(selectedTariff) === subscriptionData.tarif &&
                            'Оплатить тариф'}
                    </Button>
                </Center>
            </>
        )
    }

    return null
}
