import { useTranslation } from 'react-i18next'
import { Tabs } from '@mantine/core'
import { ReactNode } from 'react'
import { useStyles } from './PaymentTabs.styles'
import { PaymentMain } from '../PaymentMain/PaymentMain'
import { PaymentAlt } from '../PaymentAlt/PaymentAlt'

interface PaymentTabsProps {}

export const PaymentTabs = (props: PaymentTabsProps) => {
    const { classes } = useStyles()
    const { t } = useTranslation()

    return (
        <Tabs defaultValue="Russia" radius="md">
            <Tabs.List grow position="apart" mb={40}>
                <Tabs.Tab value="Russia">Оплата из РФ</Tabs.Tab>
                <Tabs.Tab value="isNoteRussia">Оплата не из РФ</Tabs.Tab>
            </Tabs.List>
            <Tabs.Panel value="Russia">
                <PaymentMain />
            </Tabs.Panel>
            <Tabs.Panel value="isNoteRussia">
                <PaymentAlt />
            </Tabs.Panel>
        </Tabs>
    )
}
