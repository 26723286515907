import classNames from 'classnames'
import { FC, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Card, Center, CopyButton, Grid, Group, Image, Stack, Text, Tooltip } from '@mantine/core'
import { ReleaseListItem } from 'entities/Release/model/types/release'
import { TextDescriptionRight } from 'shared/ui/TextDescriptionRight/TextDescriptionRight'
import dayjs from 'dayjs'
import { useResponsive } from 'shared/hooks/useResponsive/useResponsive'
import { truncateText } from 'shared/tools/truncateText'
import { Link } from 'react-router-dom'
import { RoutePath } from 'shared/configs/routeConfig/routeConfig'
import { releaseType } from '../../model/tools/releaseType'
import { useStyles } from './ReleaseCard.styles'
import { releaseStatus } from '../../model/consts/releaseConsts'

interface ReleaseCardProps {
    release: ReleaseListItem
    actionMenu: ReactNode
    isTable: boolean
}

export const ReleaseCard: FC<ReleaseCardProps> = (props) => {
    const { isTable, release, actionMenu } = props

    const { classes } = useStyles()
    const { t } = useTranslation()
    const { xs } = useResponsive()

    const isLinkDetail = release.status === 3 || release.status === 2 || release.status === 8 || release.status === 4

    const statusClasses: { [key: number]: string } = {
        1: classes.ready,
        2: classes.error,
        3: classes.published,
        4: classes.moderation,
        5: classes.review,
        6: classes.draft,
        7: classes.deleted,
        8: classes.rejected,
    }

    const renderName = () => {
        if (isLinkDetail) {
            return (
                <Link to={`${RoutePath.detail_release}#${release.uid}`}>
                    <Tooltip label={release.name} disabled={release.name.length < 22}>
                        <Text ta="center" span color={isLinkDetail ? 'teal.8' : ''}>
                            {truncateText(release.name, 21)}
                        </Text>
                    </Tooltip>
                </Link>
            )
        } else {
            return (
                <Tooltip label={release.name} disabled={release.name.length < 22}>
                    <Text ta="center" span color={isLinkDetail ? 'teal.8' : ''}>
                        {truncateText(release.name, 21)}
                    </Text>
                </Tooltip>
            )
        }
    }

    const renderMainSingers = () => {
        return release.main_singers.map((singer, index) => (
            <Tooltip label={singer} key={index} disabled={singer.length < 16}>
                <Text span={index === 0}>
                    {truncateText(singer, 15)}
                    {index === release.main_singers.length - 1 ? '' : ','}
                </Text>
            </Tooltip>
        ))
    }

    if (isTable) {
        return (
            <tr>
                <td>
                    <Center>
                        <Image src={release.ava_short_link} height={60} width={60} />
                    </Center>
                </td>
                <td>
                    {isLinkDetail ? (
                        <Link to={`${RoutePath.detail_release}#${release.uid}`}>{renderName()}</Link>
                    ) : (
                        renderName()
                    )}
                </td>
                <td>{renderMainSingers()}</td>
                <td>
                    <Text ta={'center'}>{releaseType[release.type]}</Text>
                </td>
                <td>
                    <Text ta={'center'}>
                        {release.public_date !== null ? dayjs(release.public_date).format('DD.MM.YYYY') : ''}
                    </Text>
                </td>
                <td className={statusClasses[release.status]}>
                    <Text ta={'center'}>{releaseStatus[release.status]}</Text>
                </td>
                <td>
                    <Center>
                        {release.upc !== null && release.upc !== '' && (
                            <CopyButton value={release.upc}>
                                {({ copied, copy }) => (
                                    <Button color={copied ? 'teal.8' : 'teal.3'} onClick={copy} compact w={140} px={10}>
                                        {copied ? 'UPC скопирован' : truncateText(release.upc, 15)}
                                    </Button>
                                )}
                            </CopyButton>
                        )}
                    </Center>
                </td>
                <td>
                    <Center>{actionMenu}</Center>
                </td>
            </tr>
        )
    } else {
        return (
            <Card mb={25} className={classNames(classes.releaseCard)} shadow="sm" padding="lg" radius="md" withBorder>
                <Grid>
                    <Grid.Col span={12} maw={xs ? '60%' : 200} mx="auto">
                        <Image src={release.ava_link} width={'100%'} />
                    </Grid.Col>
                    <Grid.Col span={xs ? 12 : 'auto'}>
                        <Grid align="flex-end">
                            <Grid.Col span={9}>
                                <Stack>
                                    <TextDescriptionRight leftText="Название:" rightText={renderName()} />
                                    <TextDescriptionRight leftText="Исполнитель:" rightText={renderMainSingers()} />
                                    <TextDescriptionRight
                                        leftText="Тип релиза:"
                                        rightText={releaseType[release.type]}
                                    />
                                    <TextDescriptionRight
                                        leftText="Дата релиза"
                                        rightText={
                                            release.public_date !== null
                                                ? dayjs(release.public_date).format('DD.MM.YYYY')
                                                : ''
                                        }
                                    />
                                    <TextDescriptionRight
                                        leftText="Статус:"
                                        rightText={
                                            <Text className={statusClasses[release.status]} span>
                                                {releaseStatus[release.status]}
                                            </Text>
                                        }
                                    />
                                    {release.upc !== null && release.upc !== '' && (
                                        <CopyButton value={release.upc}>
                                            {({ copied, copy }) => (
                                                <Button
                                                    color={copied ? 'teal.8' : 'teal.3'}
                                                    onClick={copy}
                                                    compact
                                                    maw={200}
                                                >
                                                    {copied
                                                        ? 'UPC скопирован'
                                                        : `UPC: ${truncateText(release.upc, 15)}`}
                                                </Button>
                                            )}
                                        </CopyButton>
                                    )}
                                    {/* {release.code && <TextDescriptionRight leftText="Промо:" rightText={release.code} />} */}
                                </Stack>
                            </Grid.Col>
                            <Grid.Col span={3}>
                                <Group position="right" pr={10}>
                                    {actionMenu}
                                </Group>
                            </Grid.Col>
                        </Grid>
                    </Grid.Col>
                </Grid>
            </Card>
        )
    }
}
