import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from '@mantine/core'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'shared/hooks/useAppDispatch/useAppDispatch'
import { paymentActions } from 'features/paymentGateway/model/slices/paymentSlice'
import { PaymentTabs } from '../PaymentTabs/PaymentTabs'
import { getPaymentModalOpen } from '../../model/selectors/getPaymentModalOpen'

interface PaymentModalProps {}

export const PaymentModal: FC<PaymentModalProps> = (props) => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()
    const IsPaymentModalOpen = useSelector(getPaymentModalOpen)

    const onClose = () => {
        dispatch(paymentActions.reducerPaymentModalClose())
    }

    return (
        <Modal onClose={onClose} opened={IsPaymentModalOpen} transitionProps={{ duration: 200 }}>
            <PaymentTabs />
        </Modal>
    )
}
