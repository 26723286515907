import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import dayjs from 'dayjs'
import { TabType, UpdateDateStateScheme } from '../types/updateDate'
import { after14DayDate } from '../consts/updateDateConsts'

const initialState: UpdateDateStateScheme = {
    actual_date: null,
    public_date: dayjs(after14DayDate).format('YYYY-MM-DD'),
    isNextButtonDisabled: false,
}

export const updateDateSlice = createSlice({
    name: 'updateDate',
    initialState,
    reducers: {
        reducerPublicDate: (state, action: PayloadAction<string>) => {
            state.public_date = action.payload
        },
        reducerActualDate: (state, action: PayloadAction<string | null>) => {
            state.actual_date = action.payload
        },
        reducerNextButtonState: (
            state,
            action: PayloadAction<{ activityTab: TabType; statusPaymentQuick: boolean }>,
        ) => {
            if (action.payload.activityTab === 'quick' && !action.payload.statusPaymentQuick)
                state.isNextButtonDisabled = true
            else state.isNextButtonDisabled = false
        },
    },
    extraReducers: () => {},
})

export const { actions: updateDateActions } = updateDateSlice
export const { reducer: updateDateReducer } = updateDateSlice
