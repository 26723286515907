import { useGetInfoRelease } from 'entities/Release/api/releaseApi'
import { Box, Skeleton, Stack, Tabs, Text } from '@mantine/core'
import { useCurrentReleaseUid } from 'entities/Release'
import { IconBrandSpeedtest, IconClockFilled } from '@tabler/icons-react'
import { DatesProvider } from '@mantine/dates'
import { useEffect, useState } from 'react'
import { useAppDispatch } from 'shared/hooks/useAppDispatch/useAppDispatch'
import { useStyles } from './TabsUpdateDate.styles'
import { QuickReleaseDate } from '../QuickReleaseDate/QuickReleaseDate'
import { StandardReleaseDate } from '../StandardReleaseDate/StandardReleaseDate'
import { TabType } from '../../models/types/updateDate'
import { updateDateActions } from '../../models/slices/updateDateSlice'

export const TabsUpdateDate = () => {
    const { classes } = useStyles()

    const currentReleaseUID = useCurrentReleaseUid()
    const dispatch = useAppDispatch()
    const { data: releaseInfo, isLoading: isLoadingReleaseInfo } = useGetInfoRelease({ uid: currentReleaseUID })

    const [activityTab, setActivityTab] = useState<TabType>('standard')

    const onTabChange = (value: TabType) => {
        setActivityTab(value)
    }

    useEffect(() => {
        dispatch(
            updateDateActions.reducerNextButtonState({
                activityTab: activityTab,
                statusPaymentQuick: releaseInfo?.result.fast_publication ?? false,
            }),
        )

        if (releaseInfo && releaseInfo.result.fast_publication) {
            setActivityTab('quick')
        }
    }, [activityTab, releaseInfo])

    if (isLoadingReleaseInfo) {
        return (
            <Stack>
                <Skeleton width={'100%'} height={60} />
                <Skeleton width={'60%'} height={200} />
                <Skeleton width={'100%'} height={60} />
            </Stack>
        )
    }

    if (releaseInfo) {
        const { fast_publication, public_date, status, soon_possible } = releaseInfo.result

        return (
            <DatesProvider settings={{ locale: 'ru', firstDayOfWeek: 0, weekendDays: [0] }}>
                <Tabs
                    defaultValue={'standard'}
                    variant="pills"
                    keepMounted={false}
                    unstyled
                    classNames={{ tab: classes.tab, tabsList: classes.tabsList, tabIcon: classes.tabIcon }}
                    onTabChange={onTabChange}
                    value={activityTab}
                >
                    <Tabs.List grow mb={35} position="center">
                        {!fast_publication && (
                            <Tabs.Tab value="standard" icon={<IconClockFilled size="1.3rem" />}>
                                Стандартно
                            </Tabs.Tab>
                        )}
                        <Tabs.Tab value="quick" icon={<IconBrandSpeedtest size="1.3rem" />}>
                            {fast_publication
                                ? 'Поздравляем! Вы оплатили функцию быстрой загрузки'
                                : 'Быстрая загрузка'}
                        </Tabs.Tab>
                    </Tabs.List>
                    {fast_publication && (
                        <Box className={classes.textSuccess}>Теперь вам доступны более ранние даты релиза</Box>
                    )}
                    {!fast_publication && (
                        <Tabs.Panel value="standard">
                            <StandardReleaseDate infoRelease={{ public_date, status, soon_possible }} />
                        </Tabs.Panel>
                    )}
                    <Tabs.Panel value="quick">
                        <QuickReleaseDate infoRelease={{ public_date, soon_possible, fast_publication }} />
                    </Tabs.Panel>
                </Tabs>
            </DatesProvider>
        )
    }

    return <Text>Ошибка загрузки информации о треке</Text>
}
