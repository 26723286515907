import classNames from 'classnames'
import { FC, ReactNode } from 'react'
import { Text } from '@mantine/core'
import { useStyles } from './TextDescriptionRight.styles'

interface TextDescriptionRightProps {
    className?: string
    leftText: string
    rightText?: string | null | ReactNode
    rightLink?: string
    urlLink?: string
}

export const TextDescriptionRight: FC<TextDescriptionRightProps> = (props) => {
    const { className, leftText, rightText, rightLink, urlLink } = props

    const { classes } = useStyles()

    return (
        <div className={classNames(classes.textDescriptionRight, {}, [className])}>
            <Text span mr={5} color="gray.7">
                {leftText}
            </Text>
            {rightText && <Text span>{rightText}</Text>}
            {rightLink && (
                <Text component="a" href={urlLink} target="_blank">
                    {rightLink}
                </Text>
            )}
        </div>
    )
}
