import { rtkApi } from 'shared/api/rtkApi'
import { UpdateDateRequest } from '../models/types/updateDate'

const updateDateApi = rtkApi.injectEndpoints({
    endpoints: (build) => ({
        updateDateRelease: build.mutation<any, UpdateDateRequest>({
            query: (body) => ({
                url: '/release/update',
                method: 'POST',
                body,
            }),
            invalidatesTags: [{ type: 'releaseInfo', id: 'info' }],
        }),
        confirmStep3: build.mutation<any, { uid: string }>({
            query: (body) => ({
                url: '/release/step3_confirm',
                method: 'POST',
                body,
            }),
        }),
    }),
})

export const useUpdateDateRelease = updateDateApi.useUpdateDateReleaseMutation
export const useConfirmStep3 = updateDateApi.useConfirmStep3Mutation
