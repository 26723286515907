import { Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import { routeConfig } from 'shared/configs/routeConfig/routeConfig'
import { ExitButton } from 'widgets/ExitButton'
import { PageLoader } from 'widgets/PageLoader'
import { ScrollToTop } from './ScrollToTop'
import { RequireAuth } from './RequireAuth'

const AppRouter = () => (
    <Suspense fallback={<PageLoader />}>
        <ScrollToTop />
        <Routes>
            {Object.values(routeConfig).map(({ element, path }) => (
                <Route
                    key={path}
                    path={path}
                    element={
                        <RequireAuth>
                            <div className="page-wrapper">
                                <ExitButton page={true} />
                                {element}
                            </div>
                        </RequireAuth>
                    }
                />
            ))}
        </Routes>
    </Suspense>
)

export default AppRouter
