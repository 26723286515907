import { createStyles } from '@mantine/core'

export const useStyles = createStyles((theme) => ({
    trackItemDetailRelease: {
        marginBottom: 20,
    },
    track: {
        border: '0.0625rem solid #dee2e6',
        borderRadius: 10,
        padding: '10px 30px',
        minHeight: 70,
        boxShadow: '0px 16px 14px -13px rgba(97, 97, 97, 0.15)',
        [theme.fn.smallerThan('xs')]: {
            padding: '10px 15px',
        },
    },
    trackActivy: {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        borderBottom: 'none',
        boxShadow: 'none',
    },
    titleBtn: {
        width: '100%',
    },
    content: {
        borderBottom: '0.0625rem solid #dee2e6',
        borderRight: '0.0625rem solid #dee2e6',
        borderLeft: '0.0625rem solid #dee2e6',
        borderBottomRightRadius: 10,
        borderBottomLeftRadius: 10,
        opacity: 0,
        transition: 'none',
        maxHeight: 0,
        overflow: 'hidden',
        boxShadow: 'none',
        paddingBottom: 30,
        padding: 0,
    },
    contentActivity: {
        opacity: 1,
        maxHeight: '1000px',
        transition: '0.5s',
        boxShadow: '0px 16px 14px -13px rgba(97, 97, 97, 0.15)',
        padding: '10px 30px',
        [theme.fn.smallerThan('xs')]: {
            padding: '10px 15px',
        },
    },
}))
