import { createStyles, rem } from '@mantine/core'

export const useStyles = createStyles((theme) => ({
    tabsUpdateDate: {},
    tab: {
        ...theme.fn.focusStyles(),
        width: '100%',
        backgroundColor: theme.colors.gray[1],
        color: theme.black,
        border: `${rem(1)} solid ${theme.colors.gray[4]}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: `${theme.spacing.xs} ${theme.spacing.md}`,
        fontSize: theme.fontSizes.md,
        fontWeight: 600,
        '&:first-of-type': {
            borderTopLeftRadius: theme.radius.md,
            borderBottomLeftRadius: theme.radius.md,
        },

        '&:last-of-type': {
            borderTopRightRadius: theme.radius.md,
            borderBottomRightRadius: theme.radius.md,
        },

        '&[data-active]': {
            backgroundColor: theme.colors.primary[6],
            borderColor: theme.colors.primary[6],
            color: theme.white,
        },

        [theme.fn.smallerThan('sm')]: {
            fontSize: theme.fontSizes.sm,
            padding: `${theme.spacing.xs} ${theme.spacing.xs}`,
        },
    },
    tabsList: {
        display: 'flex',
    },

    tabIcon: {
        marginRight: theme.spacing.xs,
        display: 'flex',
        alignItems: 'center',
    },
    textSuccess: {
        fontSize: theme.fontSizes.lg,
        fontWeight: 600,
        textAlign: 'center',
        marginBottom: theme.spacing.xl,
    },
}))
