import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Title, Box, LoadingOverlay, Text } from '@mantine/core'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { BorderWindow } from 'shared/ui/BorderWindow/BorderWindow'
import { useSelector } from 'react-redux'
import { EmptyStatList } from 'shared/ui/EmptyStatList/EmptyStatList'
import { getChartReleases } from '../../model/selectors/getChartReleases/getChartReleases'
import { useStyles } from './StatChartReleases.styles'
import { getStatisticsLoading } from '../../model/selectors/getStatisticsLoading/getStatisticsLoading'

interface StatChartReleasesProps {
    className?: string
}

export const StatChartReleases: FC<StatChartReleasesProps> = (props) => {
    const { className } = props

    const { classes } = useStyles()
    const { t } = useTranslation()

    const chartData = useSelector(getChartReleases)
    const chartLoading = useSelector(getStatisticsLoading)
    const [options, setOptions] = useState<Highcharts.Options>({
        chart: {
            type: 'areaspline',
            width: null,
            height: null,
            plotBackgroundColor: '#f8f9fa',
            backgroundColor: '#f8f9fa',
        },
        title: {
            text: undefined,
        },
        xAxis: {
            categories: [],
            type: 'datetime',
        },
        yAxis: {
            title: undefined,
        },
        credits: {
            enabled: false,
        },
        legend: {
            enabled: false,
        },
        plotOptions: {
            areaspline: {
                fillColor: {
                    linearGradient: {
                        x1: 0,
                        y1: 0,
                        x2: 0,
                        y2: 1,
                    },
                    stops: [
                        [0, 'rgba(29, 182, 160, 0.8)'],
                        [1, 'rgba(29, 182, 160, 0.3)'],
                    ],
                },
            },
        },
        series: [],
    })

    useEffect(() => {
        if (chartData !== undefined) {
            const newData = chartData.list.data.map((item: any) => item)
            const newCategories = chartData.list.labels.map((item: any) => item.date)
            setOptions((prevOptions) => ({
                ...prevOptions,
                xAxis: { categories: newCategories },
                series: [
                    {
                        data: newData,
                        type: 'areaspline',
                        name: 'Прослушивания',
                        color: '#00AE94',
                        lineColor: '#1DB6A0',
                    },
                ],
            }))
        }
    }, [chartData])

    return (
        <BorderWindow>
            <Title order={2}>
                {chartData.streamings !== undefined && chartData.streamings !== 0
                    ? chartData.streamings
                    : 'Количество прослушиваний за период'}
            </Title>
            <Text color="gray" mb="xl">
                Аудио стриминг
            </Text>
            <Box pos={'relative'} mih={250} className={classes.chartWrapper}>
                <LoadingOverlay visible={chartLoading} />
                {chartData.list.data.length === 0 ? (
                    <EmptyStatList />
                ) : (
                    <HighchartsReact highcharts={Highcharts} options={options} />
                )}
            </Box>
        </BorderWindow>
    )
}
