import { ReactComponent as UserProfileSVG } from 'shared/assets/icons/user-profile.svg'
import { ReactComponent as MyReleasesSVG } from 'shared/assets/icons/my-releases.svg'
import { ReactComponent as DashboardSVG } from 'shared/assets/icons/dashboard.svg'
import { ReactComponent as BalanceSVG } from 'shared/assets/icons/card.svg'
import { ReactComponent as CertificationSVG } from 'shared/assets/icons/certification.svg'
import { ReactComponent as RubleSVG } from 'shared/assets/icons/ruble.svg'
import { ReactComponent as FaqSVG } from 'shared/assets/icons/help.svg'
import { ReactComponent as TeachingSVG } from 'shared/assets/icons/teaching.svg'
import { RoutePath } from 'shared/configs/routeConfig/routeConfig'
import { MenuItemType } from 'widgets/Menu/model/types/menu'

export const mainMenuItemsList: MenuItemType[] = [
    {
        text: 'Профиль',
        path: 'https://yourtunes.net/ytnt/profile',
        Icon: UserProfileSVG,
    },
    {
        text: 'Мои релизы',
        // path: RoutePath.adding_release_step3,
        // path: RoutePath.detail_release,
        // path: RoutePath.release_list,
        path: 'https://yourtunes.net/relizy',
        Icon: MyReleasesSVG,
    },
    {
        text: 'Статистика',
        // path: RoutePath.statistics,
        path: 'https://yourtunes.net/statistika',
        Icon: DashboardSVG,
    },
    {
        text: 'Баланс',
        // path: RoutePath.balance,
        path: 'https://yourtunes.net/balans',
        Icon: BalanceSVG,
    },
    {
        text: 'Фин. аналитика',
        path: RoutePath.financial_analytics,
        // path: 'https://yourtunes.net/ytn/financial-analytics',
        Icon: RubleSVG,
    },
    {
        text: 'Сертификация',
        // path: RoutePath.financial_analytics,
        path: 'https://yourtunes.net/cert/certification',
        Icon: CertificationSVG,
    },
]

export const secondaryMenuItemsList: MenuItemType[] = [
    {
        text: 'Помощь',
        // path: RoutePath.faq,
        path: 'https://yourtunes.net/lk-help-faq',
        Icon: FaqSVG,
    },
]
