import { rtkApi } from 'shared/api/rtkApi'
import { VerificationUser } from '../model/types/verificationUser'

const verificationUserApi = rtkApi.injectEndpoints({
    endpoints: (build) => ({
        setVerificationUser: build.mutation<any, VerificationUser>({
            query: (body) => ({
                url: '/user/set_verification',
                method: 'POST',
                body,
            }),
            invalidatesTags: [{ type: 'user', id: 'info' }],
        }),
    }),
})

export const useSetVerificationUser = verificationUserApi.useSetVerificationUserMutation
