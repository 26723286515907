import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { ReleaseStateScheme } from '../types/release'

const initialState: ReleaseStateScheme = {}

export const releaseSlice = createSlice({
    name: 'release',
    initialState,
    reducers: {},
    extraReducers: () => {},
})

export const { actions: releaseActions } = releaseSlice
export const { reducer: releaseReducer } = releaseSlice
