import classNames from 'classnames'
import { FC, ReactNode } from 'react'
import { useStyles } from './BorderWindow.styles'

interface BorderWindowProps {
    className?: string
    children: ReactNode
}

export const BorderWindow: FC<BorderWindowProps> = (props) => {
    const { className, children } = props

    const { classes } = useStyles()

    return <div className={classNames(classes.borderWindow, {}, [className])}>{children}</div>
}
