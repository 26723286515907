import { Stepper, Text } from '@mantine/core'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useResponsive } from 'shared/hooks/useResponsive/useResponsive'

interface ReleaseStepperProps {
    activity: number
}

export const ReleaseStepper: FC<ReleaseStepperProps> = (props) => {
    const { activity } = props

    const { t } = useTranslation()

    const { sm } = useResponsive()

    return (
        <Stepper
            iconSize={28}
            active={activity}
            mb={50}
            orientation={sm ? 'vertical' : 'horizontal'}
            completedIcon={sm ? activity + 1 : undefined}
        >
            {!sm || (sm && activity === 0) ? (
                <Stepper.Step
                    sx={sm ? { alignItems: 'center', justifyContent: 'center' } : undefined}
                    description={
                        <Text color={activity === 0 ? 'black' : 'gray'} fz="sm">
                            Загрузите обложку релиза
                        </Text>
                    }
                />
            ) : null}

            {!sm || (sm && activity === 1) ? (
                <Stepper.Step
                    sx={sm ? { alignItems: 'center', justifyContent: 'center' } : undefined}
                    description={
                        <Text color={activity === 1 ? 'black' : 'gray'} fz="sm">
                            Заполните информацию о релизе
                        </Text>
                    }
                />
            ) : null}

            {!sm || (sm && activity === 2) ? (
                <Stepper.Step
                    sx={sm ? { alignItems: 'center', justifyContent: 'center' } : undefined}
                    description={
                        <Text color={activity === 2 ? 'black' : 'gray'} fz="sm">
                            Заполните треклист и загрузите треки
                        </Text>
                    }
                />
            ) : null}

            {!sm || (sm && activity === 3) ? (
                <Stepper.Step
                    sx={sm ? { alignItems: 'center', justifyContent: 'center' } : undefined}
                    description={
                        <Text color={activity === 3 ? 'black' : 'gray'} fz="sm">
                            Выберите дату публикации
                        </Text>
                    }
                />
            ) : null}

            {!sm || (sm && activity === 4) ? (
                <Stepper.Step
                    sx={sm ? { alignItems: 'center', justifyContent: 'center' } : undefined}
                    description={
                        <Text color={activity === 4 ? 'black' : 'gray'} fz="sm">
                            Отправьте на модерацию
                        </Text>
                    }
                />
            ) : null}
        </Stepper>
    )
}
