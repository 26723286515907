import { rtkApi } from 'shared/api/rtkApi'
import {
    ReleaseInfoResponse,
    ReleaseUidRequest,
    TrackListResponse,
    ReleaseUpdateRequest,
    ReleaseImageResponse,
    TrackInfoResponse,
    TrackUidRequest,
    ReleaseListResponse,
} from '../model/types/release'

const releaseApi = rtkApi.injectEndpoints({
    endpoints: (build) => ({
        getReleaseList: build.query<ReleaseListResponse, void>({
            query: () => ({
                url: '/release/list',
            }),
            providesTags: (response, error, arg) =>
                response?.result ? response.result.map(({ uid }) => ({ type: 'releases', id: uid })) : [],
        }),
        getInfoRelease: build.query<ReleaseInfoResponse, ReleaseUidRequest>({
            query: (body) => ({
                url: '/release/get',
                method: 'POST',
                body,
            }),
            providesTags: () => [{ type: 'releaseInfo', id: 'info' }],
        }),
        getImageRelease: build.query<ReleaseImageResponse, ReleaseUpdateRequest>({
            query: (body) => ({
                url: '/release/get_ava',
                method: 'POST',
                body,
            }),
        }),
        getTrackList: build.query<TrackListResponse, ReleaseUidRequest>({
            query: (body) => ({
                url: '/release/track_list',
                method: 'POST',
                body,
            }),
            providesTags: (response, error, arg) =>
                response?.result ? response.result.map(({ uid }) => ({ type: 'tracklist', id: uid })) : [],
        }),
        lazyGetInfoTrack: build.query<TrackInfoResponse, TrackUidRequest>({
            query: (body) => ({
                url: '/release/get_track',
                method: 'POST',
                body,
            }),
        }),
    }),
})

export const useGetReleaseList = releaseApi.useGetReleaseListQuery
export const useGetInfoRelease = releaseApi.useGetInfoReleaseQuery
export const useGetTrackList = releaseApi.useGetTrackListQuery
export const useGetImageRelease = releaseApi.useGetImageReleaseQuery
export const useLazyGetImageRelease = releaseApi.useLazyGetImageReleaseQuery

export const useLazyGetInfoTrack = releaseApi.useLazyLazyGetInfoTrackQuery
