import { FC, useEffect, useState } from 'react'
import { Accordion, Box, Button, List, Skeleton, Stack, Switch, Text } from '@mantine/core'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'shared/hooks/useAppDispatch/useAppDispatch'
import { DatePickerInput } from '@mantine/dates'
import dayjs from 'dayjs'
import { IconCalendar } from '@tabler/icons-react'
import { useGetUserSubscription } from 'entities/Subscription/api/subscriptionApi'
import { updateDateActions } from '../../models/slices/updateDateSlice'
import { after14DayDate } from '../../models/consts/updateDateConsts'
import { getPublicDate } from '../../models/selectors/getPublicDate'
import { useModalInUpdateDate } from '../../models/hooks/useModalInUpdateDate'
import { UpdateDatePrevInfo, UpdateDateRequest } from '../../models/types/updateDate'
import { useCurrentReleaseUid } from '../../../../entities/Release'
import { useUpdateDateRelease } from '../../api/updateDateApi'
import { InputActualDate } from '../InputActualDate/InputActualDate'

interface QuickReleaseDateProps {
    infoRelease: UpdateDatePrevInfo
}

export const QuickReleaseDate: FC<QuickReleaseDateProps> = (props) => {
    const { infoRelease } = props

    const dispatch = useAppDispatch()

    const [accordion, setAccordion] = useState<string | null>('warn')

    const publicDate = useSelector(getPublicDate)

    const [soonPossible, setSoonPossible] = useState<boolean>(infoRelease.soon_possible)
    const { data: subscriptionData, isLoading: isLoadingSubscription } = useGetUserSubscription()
    const [updateQuickRelease, { isLoading: isLoadingUpdateDate }] = useUpdateDateRelease()

    const releaseUID = useCurrentReleaseUid()
    const { openTariffModal, openPaymentQuickRelease, openPaymentSubscription } = useModalInUpdateDate(
        subscriptionData ?? null,
    )

    const updatePublicDate = (value: Date | null) => {
        if (!value) return
        dispatch(updateDateActions.reducerPublicDate(dayjs(value).format('YYYY-MM-DD')))
    }

    const updateDateQuick = () => {
        const todayDay = dayjs().day()

        const dayToAddMapping: { [key: number]: number } = {
            1: 2, // Понедельник
            2: 2, // Вторник
            3: 2, // Среда
            4: 2, // Четверг
            5: 4, // Пятница
            6: 3, // Суббота
            0: 3, // Воскресенье
        }

        const daysToAdd = dayToAddMapping[todayDay]
        return dayjs().add(daysToAdd, 'days').toDate()
    }

    useEffect(() => {
        updatePublicDate(updateDateQuick())
        if (infoRelease.fast_publication) {
            setAccordion(null)
        }
    }, [infoRelease])

    const openModalPayment = async () => {
        // Проверяем выбрана ли подписка
        if (subscriptionData?.tarif === null) {
            openTariffModal()
            return
        }

        // Проверяем оплаченую подписку
        if (!subscriptionData?.is_pay) {
            openPaymentSubscription()
            return
        }

        const sendData: UpdateDateRequest = {
            uid: releaseUID,
            soon_possible: soonPossible,
        }

        try {
            await updateQuickRelease(sendData).unwrap()
            openPaymentQuickRelease(releaseUID)
        } catch (e: any) {
            console.log(e.data.message)
        }
    }

    return (
        <div>
            <Switch
                label="Как можно скорее"
                checked={soonPossible}
                onChange={(event: any) => setSoonPossible(event.currentTarget.checked)}
                mb="xl"
            />
            {soonPossible ? (
                <Stack spacing={'md'}>
                    <Text ta={'justify'}>
                        Наши модераторы, позаботятся о том, чтобы Ваш релиз оказался как можно быстрее на площадках
                    </Text>
                    <Text ta={'justify'}>Стоимость услуги 500 рублей.</Text>
                    <Text ta={'justify'}>
                        При выборе данного параметра публикация релиза на площадках может занимать от нескольких часов
                        до 2 дней. Если релиз не будет опубликован по каким-либо причинам на площадках в указанный
                        период времени, оплату за услугу можно вернуть, отправив запрос и чек оплаты в техническую
                        поддержку
                    </Text>
                </Stack>
            ) : (
                <Stack spacing={'md'}>
                    <Text ta={'justify'}>
                        Можете выбрать ближайшую дату ускоренного размещения Вашего релиза Стоимость услуги 500 рублей.
                    </Text>
                    <Text ta={'justify'}>
                        Если релиз не будет опубликован по каким-либо причинам на площадках в указанную дату, оплату за
                        услугу можно вернуть, отправив запрос и чек оплаты в техническую поддержку
                    </Text>
                </Stack>
            )}
            <Box mb="xl">
                <Accordion value={accordion} onChange={setAccordion}>
                    <Accordion.Item value="warn">
                        <Accordion.Control>
                            <Text fw={600}>Важно знать:</Text>
                        </Accordion.Control>
                        <Accordion.Panel>
                            <List type="ordered" spacing="xs">
                                <List.Item ta={'justify'}>
                                    В редких случаях публикация релиза на некоторых площадках при ускоренном размещение
                                    может происходить с задержкой в несколько часов. Например : релиз на Apple music
                                    вышел в 00:00 часов по московскому времени, а VK music в 10:00 по московскому
                                    времени
                                </List.Item>
                                <List.Item ta={'justify'}>
                                    В случае, если ваш релиз был опубликован на какой-либо из площадок в указанный вами
                                    срок, деньги за услугу возврату НЕ подлежат.
                                </List.Item>
                                <List.Item ta={'justify'}>
                                    Если релиз находится на стадии публикации и дата публикации не наступила, денежные
                                    средства возврату НЕ подлежат.
                                </List.Item>
                            </List>
                        </Accordion.Panel>
                    </Accordion.Item>
                </Accordion>
            </Box>
            {!soonPossible && (
                <DatePickerInput
                    value={dayjs(publicDate).toDate()}
                    onChange={updatePublicDate}
                    label="Дата публикации"
                    placeholder="Выберите дату"
                    maw={200}
                    mb="md"
                    valueFormat="DD.MM.YYYY"
                    minDate={updateDateQuick()}
                    icon={<IconCalendar size="1.1rem" stroke={1.5} />}
                    maxDate={after14DayDate}
                />
            )}
            {}
            {infoRelease.fast_publication ? (
                <>
                    <InputActualDate />
                    <Text color="gray" mb={40}>
                        Если релиз уже вышел в свет и необходимо отобразить дату релиза. Если релиз новый, оставьте поле
                        пустым.
                    </Text>
                </>
            ) : (
                <>
                    {isLoadingSubscription ? (
                        <Skeleton width={'100%'} height={60} />
                    ) : (
                        <Button
                            color="dark"
                            fullWidth
                            m={'30px auto'}
                            onClick={openModalPayment}
                            loading={isLoadingUpdateDate}
                        >
                            Перейти к оплате
                        </Button>
                    )}
                </>
            )}
        </div>
    )
}
