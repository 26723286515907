import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Drawer } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { useResponsive } from 'shared/hooks/useResponsive/useResponsive'
import { useStyles } from './VerificationFormButton.styles'
import { VerificationForm } from '../VerificationForm/VerificationForm'

export const VerificationFormButton = memo((props) => {
    const {} = props

    const { classes } = useStyles()
    const { t } = useTranslation()

    const { md, lg, sm } = useResponsive()

    const [opened, { close, open }] = useDisclosure()

    return (
        <>
            <Button className={classes.verificationFormButton} onClick={open}>
                Верифицировать аккаунт
            </Button>
            <Drawer
                opened={opened}
                onClose={close}
                position="right"
                size={sm ? '100%' : md ? '80%' : lg ? '60%' : '45%'}
                title="Верификация"
            >
                <VerificationForm onClose={close} />
            </Drawer>
        </>
    )
})
