import classNames from 'classnames'
import { FC } from 'react'
import { UserAvatar } from 'entities/User'
import { HamburgerButton } from 'shared/ui/HamburgerButton/HamburgerButton'
import { useDisclosure } from '@mantine/hooks'
import { MenuNavbar } from 'widgets/Menu/ui/MenuNavbar/MenuNavbar'
import { useStyles } from './Navbar.styles'

interface NavbarProps {
    className?: string
}

export const Navbar: FC<NavbarProps> = (props) => {
    const { className } = props

    const [opened, { toggle }] = useDisclosure(false)

    const { classes } = useStyles()

    return (
        <div className={classNames(classes.navbar, {}, [className])}>
            <div className={classes.navbarHeader}>
                <UserAvatar className={classes.ava} />
                <HamburgerButton opened={opened} onClick={toggle} />
            </div>
            <MenuNavbar opened={opened} />
        </div>
    )
}
