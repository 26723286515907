import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Text, Image, Button, CopyButton } from '@mantine/core'
import { useResponsive } from 'shared/hooks/useResponsive/useResponsive'
import { useStyles } from './StatChartReleasesTopItem.styles'
import { StatisticsReleaseTop } from '../../model/types/statistics'

interface StatChartReleasesTopItemProps {
    className?: string
    index: number
    release: StatisticsReleaseTop
}

export const StatChartReleasesTopItem: FC<StatChartReleasesTopItemProps> = (props) => {
    const { className, index, release } = props

    const { classes } = useStyles()
    const { t } = useTranslation()

    const { md } = useResponsive()

    return (
        <>
            <Grid.Col span={'content'} md="content">
                <Text>{index + 1}.</Text>
            </Grid.Col>
            <Grid.Col span={'content'} px={20} md="content">
                <Image src={release.ava_short_link} height={50} width={50} />
            </Grid.Col>
            <Grid.Col span={'auto'}>
                <Grid>
                    <Grid.Col span={12} md="auto">
                        <Text>{release.name}</Text>
                    </Grid.Col>
                    <Grid.Col span={12} md="auto">
                        <Text>{release.main_singers}</Text>
                    </Grid.Col>
                    <Grid.Col span={12} md="auto">
                        <Text ta={!md ? 'center' : 'left'}>{release.streamings}</Text>
                    </Grid.Col>
                    <Grid.Col span={12} md="auto">
                        {/* <Text ta={!md ? 'center' : 'left'}>{}</Text> */}
                        <CopyButton value={release.upc}>
                            {({ copied, copy }) => (
                                <Button
                                    color={copied ? 'teal.8' : 'teal.3'}
                                    onClick={copy}
                                    compact
                                    fullWidth
                                    maw={'100%'}
                                >
                                    {copied ? 'UPC скопирован' : release.upc}
                                </Button>
                            )}
                        </CopyButton>
                    </Grid.Col>
                </Grid>
            </Grid.Col>
        </>
    )
}
