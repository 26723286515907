import classNames from 'classnames'
import { FC, ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { Title, Stack, Group, Anchor, Button, Box, Drawer, Text, Center, Popover, Skeleton } from '@mantine/core'
import { TextDescriptionRight } from 'shared/ui/TextDescriptionRight/TextDescriptionRight'
import { ReactComponent as VkSVG } from 'shared/assets/icons/icon_vk.svg'
import { ReactComponent as FacebookSVG } from 'shared/assets/icons/icon_facebook.svg'
import { ReactComponent as InstagramSVG } from 'shared/assets/icons/icon_instagram.svg'
import { ReactComponent as TelegramSVG } from 'shared/assets/icons/icon_telegram.svg'
import { IconCircleCheckFilled } from '@tabler/icons-react'
import { useDisclosure } from '@mantine/hooks'
import { useResponsive } from 'shared/hooks/useResponsive/useResponsive'
import { useGetUserInfo } from 'entities/User/api/userApi'
import { useStyles } from './UserProfileCard.styles'

interface UserProfileCardProps {
    className?: string
    ButtonChangeName: ReactElement
    ButtonChangePassword: ReactElement
    ButtonChangeSocial: ReactElement
    VerificationFormButton: ReactElement
}

export const UserProfileCard: FC<UserProfileCardProps> = (props) => {
    const { className, ButtonChangeName, ButtonChangePassword, ButtonChangeSocial, VerificationFormButton } = props

    const { classes } = useStyles()
    const { t } = useTranslation()

    const { data, isLoading } = useGetUserInfo()

    const [opened, { open, close }] = useDisclosure()

    const { sm, lg, md } = useResponsive()

    return (
        <div className={classNames(classes.userProfileCard, {}, [className])}>
            <Stack spacing={40}>
                <Group position="apart">
                    {isLoading ? <Skeleton width={250} h={28} /> : <Title order={2}>{data?.result.display_name}</Title>}
                    {ButtonChangeName}
                </Group>
                {isLoading ? (
                    <Skeleton width={'100%'} h={23} />
                ) : (
                    <TextDescriptionRight leftText="E-mail:" rightText={data?.result.email} />
                )}
                {isLoading ? (
                    <Skeleton width={'100%'} h={23} />
                ) : (
                    <TextDescriptionRight leftText="Номер телефона:" rightText={data?.result.phone} />
                )}
                {isLoading ? (
                    <Skeleton width={'100%'} h={23} />
                ) : (
                    <TextDescriptionRight
                        leftText="Реферальная ссылка:"
                        rightLink={`https://yourtunes.net/?r=${data?.result.ref_code}`}
                        urlLink={`https://yourtunes.net/?r=${data?.result.ref_code}`}
                    />
                )}
                <Group position="apart">
                    <TextDescriptionRight leftText="Пароль:" rightText="**********" />
                    {ButtonChangePassword}
                </Group>
                <Group position="apart">
                    <TextDescriptionRight leftText="Социальные сети:" />
                    {ButtonChangeSocial}
                </Group>
                {isLoading ? (
                    <Skeleton width={'100%'} h={33} />
                ) : (
                    <Group align="center">
                        {data?.result.soc_telegram && (
                            <Anchor href={`tel:${data?.result.soc_telegram}`}>
                                <TelegramSVG />
                            </Anchor>
                        )}
                        {data?.result.soc_vk && (
                            <Anchor href={data?.result.soc_vk} target="_blank">
                                <VkSVG />
                            </Anchor>
                        )}
                        {data?.result.soc_facebook && (
                            <Anchor href={data?.result.soc_facebook} target="_blank">
                                <FacebookSVG />
                            </Anchor>
                        )}
                        {data?.result.soc_instagram && (
                            <Anchor href={data?.result.soc_instagram} target="_blank">
                                <InstagramSVG />
                            </Anchor>
                        )}
                    </Group>
                )}
                {isLoading ? (
                    <Skeleton width={'100%'} height={36} />
                ) : (
                    <>
                        {data?.result.verified && (
                            <>
                                <Button color="dark" variant="outline" onClick={open}>
                                    <Box component="span" sx={{ color: 'green' }} mr={10}>
                                        <IconCircleCheckFilled size={20} />
                                    </Box>
                                    Аккаунт верифицирован!
                                </Button>
                                <Drawer
                                    opened={opened}
                                    onClose={close}
                                    position="right"
                                    size={sm ? '100%' : md ? '80%' : lg ? '60%' : '45%'}
                                    title="Ваши данные"
                                >
                                    <Box py={50}>
                                        <Stack>
                                            <TextDescriptionRight
                                                leftText="ФИО:"
                                                // eslint-disable-next-line max-len
                                                rightText={`${data?.result.verification_data?.first_name} ${data?.result.verification_data?.last_name} ${data?.result.verification_data?.surname}`}
                                            />
                                            <TextDescriptionRight
                                                leftText="Гражданство:"
                                                rightText={data?.result.verification_data?.citizenship}
                                            />
                                            <TextDescriptionRight
                                                leftText="Адрес регистрации / фактического пребывания:"
                                                rightText={data?.result.verification_data?.address}
                                            />
                                            <TextDescriptionRight
                                                leftText="Номер телефона:"
                                                rightText={data?.result.verification_data?.phone}
                                            />
                                            <TextDescriptionRight
                                                leftText="Дополнительный канал связи (Telegram, WhatsAPP):"
                                                rightText={data?.result.verification_data?.telegram}
                                            />
                                        </Stack>
                                        <Text mt={50} ta="justify" color="gray">
                                            Эти данные нельзя изменить напрямую, но если Вы ошиблись и хотите, что-то
                                            изменить, обратитесь в службу технической поддержки
                                        </Text>
                                        <Center mt="xl">
                                            <Button
                                                fullWidth
                                                maw={400}
                                                component="a"
                                                href="https://yourtunes.net/lk-help-support"
                                                target="_blank"
                                            >
                                                Тех. поддержка
                                            </Button>
                                        </Center>
                                    </Box>
                                </Drawer>
                            </>
                        )}
                        {data?.result.verified === false &&
                            data?.result.verification_data === null &&
                            VerificationFormButton}
                        {data?.result.verified === false && data?.result.verification_data !== null && (
                            <Popover position="bottom" withArrow shadow="md">
                                <Popover.Target>
                                    <Button color="gray" fullWidth>
                                        Аккаунт на проверке
                                    </Button>
                                </Popover.Target>
                                <Popover.Dropdown>
                                    <Text size="sm">
                                        Мы проверяем аккаунт в течение нескольких часов, пожалуйста, ожидайте.
                                    </Text>
                                </Popover.Dropdown>
                            </Popover>
                        )}
                    </>
                )}
            </Stack>
        </div>
    )
}
