import { rtkApi } from 'shared/api/rtkApi'
import { UserResponse } from '../model/types/user'

const userApi = rtkApi.injectEndpoints({
    endpoints: (build) => ({
        getUserInfo: build.query<UserResponse, void>({
            query: () => ({
                url: '/user/get_info',
            }),
            providesTags: () => [{ type: 'user', id: 'info' }],
        }),
    }),
})

export const useGetUserInfo = userApi.useGetUserInfoQuery
