import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import {
    StatisticsReleaseStore,
    StatisticsReleaseTop,
    StatisticsReleases,
    StatisticsStateScheme,
} from '../types/statistics'

const initialState: StatisticsStateScheme = {
    chartReleaseTopData: [],
    chartReleases: { list: { data: [], labels: [] } },
    statisticsLoading: false,
    datePeriod: ['', ''],
    chartReleasesStore: [],
    filterUpc: '',
}

export const statisticsSlice = createSlice({
    name: 'statistics',
    initialState,
    reducers: {
        reducerDatePeriod: (state, action: PayloadAction<string[]>) => {
            state.datePeriod = action.payload
        },
        reducerStatisticsReleaseTop: (state, action: PayloadAction<StatisticsReleaseTop[]>) => {
            state.chartReleaseTopData = action.payload
        },
        reducerStatisticsReleases: (state, action: PayloadAction<StatisticsReleases>) => {
            state.chartReleases = action.payload
        },
        reducerStatisticsLoading: (state, action: PayloadAction<boolean>) => {
            state.statisticsLoading = action.payload
        },
        reducerStatisticsReleaseStore: (state, action: PayloadAction<StatisticsReleaseStore[]>) => {
            state.chartReleasesStore = action.payload
        },
        reducerFilterUpc: (state, action: PayloadAction<string>) => {
            state.filterUpc = action.payload
        },
    },
    extraReducers: (builder) => {},
})

export const { actions: statisticsActions } = statisticsSlice
export const { reducer: statisticsReducer } = statisticsSlice
