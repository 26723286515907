import { rtkApi } from 'shared/api/rtkApi'
import { BankCards, DeleteBankCard } from '../model/types/fastPaymentCards'

const fastPaymentCardsApi = rtkApi.injectEndpoints({
    endpoints: (build) => ({
        getBankCards: build.query<BankCards, void>({
            query: () => ({
                url: '/user/bank_cards',
            }),
            providesTags: (response, error, arg) =>
                response?.list ? response.list.map(({ guid }) => ({ type: 'bankCards', id: guid })) : [],
        }),
        deleteBankCard: build.mutation<BankCards, DeleteBankCard>({
            query: (body) => ({
                url: '/pay/card_delete',
                method: 'POST',
                body,
            }),
            invalidatesTags: (result, error, { guid }) => [{ type: 'bankCards', id: guid }],
        }),
    }),
})
export const useGetBankCards = fastPaymentCardsApi.useGetBankCardsQuery
export const useLazyGetBankCards = fastPaymentCardsApi.useLazyGetBankCardsQuery
export const useDeleteBankCard = fastPaymentCardsApi.useDeleteBankCardMutation
