import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { PaymentAdditionalOption, PaymentInfo, PaymentStateScheme } from '../types/payment'

const initialState: PaymentStateScheme = {
    product: { name: '', title: '' },
    period: { name: '', title: '' },
    price: { name: '', title: '' },
    currentBalance: 0,
    isPaymentModalOpen: false,
    acceptOffer: false,
    payFromBalance: false,
    message: '',
    type: null,
}

export const paymentSlice = createSlice({
    name: 'payment',
    initialState,
    reducers: {
        reducerPaymentModalOpen: (
            state,
            action: PayloadAction<{
                product?: PaymentInfo
                period?: PaymentInfo
                price?: PaymentInfo
                isPaymentModalOpen?: boolean
                message?: string
                type: 'subscription' | 'quickRelease'
                additionalOption?: PaymentAdditionalOption
            }>,
        ) => {
            state.type = action.payload.type

            if (action.payload.product) {
                state.product = action.payload.product
            }
            if (action.payload.period) {
                state.period = action.payload.period
            }
            if (action.payload.price) {
                state.price = action.payload.price
            }
            if (typeof action.payload.isPaymentModalOpen !== 'undefined') {
                state.isPaymentModalOpen = action.payload.isPaymentModalOpen
            }
            if (action.payload.additionalOption) {
                state.additionalOption = action.payload.additionalOption
            }
            if (action.payload.message) {
                state.message = action.payload.message
            } else if (!action.payload.message) {
                state.message = ''
            }
        },
        reducerPaymentModalClose: (state) => {
            state.isPaymentModalOpen = false
            state.type = null
            state.message = ''
            state.price = { name: '', title: '' }
            state.product = { name: '', title: '' }
            state.period = { name: '', title: '' }
            state.additionalOption = undefined
        },
        reducerAcceptOffer: (state, action: PayloadAction<boolean>) => {
            state.acceptOffer = action.payload
        },
        reducerPayFromBalance: (state, action: PayloadAction<boolean>) => {
            state.payFromBalance = action.payload
        },
        reducerCurrentBalance: (state, action: PayloadAction<number>) => {
            state.currentBalance = action.payload
        },
        reducerPaymentMessage: (state, action: PayloadAction<string>) => {
            state.message = action.payload
        },
    },
    extraReducers: () => {},
})

export const { actions: paymentActions } = paymentSlice
export const { reducer: paymentReducer } = paymentSlice
