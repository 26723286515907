import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

export const useNavigtaion = (route: string, uid?: string) => {
    const navigate = useNavigate()

    const handleNavigation = useCallback(() => {
        const newPath = uid !== undefined ? `${route}#${uid}` : route
        navigate(newPath)
    }, [route, navigate, uid])

    return handleNavigation
}
