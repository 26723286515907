import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { FastPaymentCardsStateScheme } from '../types/fastPaymentCards'

const initialState: FastPaymentCardsStateScheme = {
    cardUid: '',
}

export const fastPaymentCardsSlice = createSlice({
    name: 'fastPaymentCards',
    initialState,
    reducers: {
        reducerCardUid: (state, action: PayloadAction<string>) => {
            state.cardUid = action.payload
        },
    },
    extraReducers: () => {},
})

export const { actions: fastPaymentCardsActions } = fastPaymentCardsSlice
export const { reducer: fastPaymentCardsReducer } = fastPaymentCardsSlice
