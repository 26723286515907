import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Carousel, useAnimationOffsetEffect } from '@mantine/carousel'
import { Title, Skeleton, Box, LoadingOverlay } from '@mantine/core'
import type { Embla } from '@mantine/carousel'
import { useAppDispatch } from 'shared/hooks/useAppDispatch/useAppDispatch'
import { useSelector } from 'react-redux'
import { fastPaymentCardsActions } from 'features/fastPaymentCards/model/slices/fastPaymentCardsSlice'
import { useStyles } from './CarouselBankCards.styles'
import { useGetBankCards } from '../../api/fastPaymentCardsApi'
import { BankCard } from '../BankCard/BankCard'
import { getCardUid } from '../../model/selectors/getCardUid'

interface CarouselBankCardsProps {
    sliderSize: string
}

export const CarouselBankCards: FC<CarouselBankCardsProps> = (props) => {
    const { sliderSize } = props

    const { classes } = useStyles()
    const { t } = useTranslation()
    const dispatch = useAppDispatch()

    const cardUid = useSelector(getCardUid)

    const { data: bankCardsData, isLoading: isLoadingBankCards, isFetching: isFetchingBankCards } = useGetBankCards()

    const [embla, setEmbla] = useState<Embla | null>(null)

    useAnimationOffsetEffect(embla, 200)

    const handleSetCardUid = (newVal: string) => {
        dispatch(fastPaymentCardsActions.reducerCardUid(newVal))
    }

    useEffect(() => {
        // какую карту выбираем по умолчанию
        if (bankCardsData?.list[0]) dispatch(fastPaymentCardsActions.reducerCardUid(bankCardsData?.list[0].guid))
        else dispatch(fastPaymentCardsActions.reducerCardUid(''))
    }, [bankCardsData])

    if (isLoadingBankCards) {
        return <Skeleton height={100} width={'100%'} />
    }

    if (bankCardsData?.list.length === 0) {
        return null
    }

    if (bankCardsData) {
        return (
            <Box pos={'relative'}>
                <LoadingOverlay visible={isFetchingBankCards} />
                <Title order={2} mb="xl">
                    Банковские карты
                </Title>
                <Carousel
                    getEmblaApi={setEmbla}
                    mx="auto"
                    slideSize={sliderSize}
                    controlsOffset="xs"
                    slideGap="md"
                    withControls={bankCardsData.list.length === 0 ? false : true}
                >
                    {bankCardsData.list.map((card) => (
                        <Carousel.Slide key={card.guid}>
                            <BankCard
                                guid={card.guid}
                                cardNumber={card.name.replace(/.*(\*\* \d{4})$/, '$1')}
                                selectedValue={cardUid}
                                handleSetCardUid={handleSetCardUid}
                            />
                        </Carousel.Slide>
                    ))}
                    <Carousel.Slide>
                        <BankCard
                            guid={''}
                            cardNumber={'Новая карта'}
                            selectedValue={cardUid}
                            handleSetCardUid={handleSetCardUid}
                        />
                    </Carousel.Slide>
                </Carousel>
            </Box>
        )
    }

    return null
}
