import { selectTariffActions } from 'features/selectTariff/model/slices/selectTariffSlice'
import { useAppDispatch } from 'shared/hooks/useAppDispatch/useAppDispatch'
import { paymentActions } from 'features/paymentGateway/model/slices/paymentSlice'
import { UserSubscription } from 'entities/Subscription/model/types/subscription'

export const useModalInUpdateDate = (subscriptionData: UserSubscription | null) => {
    const dispatch = useAppDispatch()
    const openTariffModal = () => {
        dispatch(
            selectTariffActions.reducerTariffModal({
                isOpen: true,
                message:
                    'У вас не выбрана подписка. Для размещения релиза сначала нужно выбрать подписку, и вы сможете продолжить.',
                startIndex: 0,
            }),
        )
    }
    const openPaymentSubscription = () => {
        dispatch(
            paymentActions.reducerPaymentModalOpen({
                isPaymentModalOpen: true,
                message:
                    'У вас не оплачена подписка. Для размещения релиза оплатите подписку, и вы сможете продолжить.',
                period: {
                    title: 'Действителен:',
                    name: `c ${subscriptionData?.date_start} по ${subscriptionData?.date_end}`,
                },
                price: { name: subscriptionData?.price?.toString() ?? '', title: 'Стоимость тарифа:' },
                product: { name: subscriptionData?.tarif_name ?? '', title: 'Название тарифа:' },
                type: 'subscription',
            }),
        )
    }

    const openPaymentQuickRelease = (releaseUID: string) => {
        dispatch(
            paymentActions.reducerPaymentModalOpen({
                isPaymentModalOpen: true,
                price: { name: '500', title: 'Стоимость услуги:' },
                product: { name: 'Быстрая загрузка релиза', title: 'Название услуги:' },
                type: 'quickRelease',
                additionalOption: { releaseUid: releaseUID },
            }),
        )
    }

    return { openTariffModal, openPaymentSubscription, openPaymentQuickRelease }
}
