import classNames from 'classnames'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { UserNameEmailCard } from 'entities/User'
import { Text } from '@mantine/core'
import { ExitButton } from 'widgets/ExitButton'
import { mainMenuItemsList, secondaryMenuItemsList } from 'shared/configs/menuConfig/menuConfig'
import { useStyles } from './MenuNavbar.styles'
import { MenuList } from '../MenuList/MenuList'

interface MenuNavbarProps {
    className?: string
    opened: boolean
}

export const MenuNavbar: FC<MenuNavbarProps> = (props) => {
    const { className, opened } = props

    const { classes } = useStyles()
    const { t } = useTranslation()

    const mods = {
        [classes.open]: opened,
        [classes.close]: !opened,
    }

    const modsMenuList = {
        [classes.view]: opened,
        [classes.hide]: !opened,
    }
    return (
        <div className={classNames(classes.menuNavbar, mods, [className])}>
            <UserNameEmailCard className={classNames(classes.menu, modsMenuList, [classes.nameWrapper])} />
            <MenuList
                dataMenuItems={mainMenuItemsList}
                className={classNames(classes.menu, modsMenuList, [classes.mainMenu])}
            />
            <Text className={classNames('', modsMenuList)}>FAQ</Text>
            <MenuList
                dataMenuItems={secondaryMenuItemsList}
                className={classNames(classes.menu, modsMenuList, [classes.secondaryMenu])}
            />
            <div className={classNames('', modsMenuList)}>
                <ExitButton navbar={true} />
            </div>
        </div>
    )
}
