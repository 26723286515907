import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@mantine/core'
import { useNavigate } from 'react-router-dom'
import { RoutePath } from 'shared/configs/routeConfig/routeConfig'
import { useStyles } from './AddingNewReleaseButton.styles'

interface AddingNewReleaseButtonProps {
    className?: string
}

export const AddingNewReleaseButton: FC<AddingNewReleaseButtonProps> = (props) => {
    const { className } = props

    const { classes } = useStyles()
    const { t } = useTranslation()
    const navigate = useNavigate()

    const handlerAddingRelease = () => {
        navigate(RoutePath.adding_release_start)
    }

    return (
        <Button fullWidth maw={500} mx={'auto'} onClick={handlerAddingRelease}>
            Добавить релиз
        </Button>
    )
}
