import { rtkApi } from 'shared/api/rtkApi'
import { BalanceInfoResponse } from '../model/types/paymentFromBalance'

const paymentFromBalanceApi = rtkApi.injectEndpoints({
    endpoints: (build) => ({
        getBalanceInfo: build.query<BalanceInfoResponse, void>({
            query: () => ({
                url: 'balance/operations',
            }),
            providesTags: () => [{ type: 'balance', id: 'info' }],
        }),
    }),
})

export const useGetBalanceInfo = paymentFromBalanceApi.useGetBalanceInfoQuery
export const useLazyGetBalanceInfo = paymentFromBalanceApi.useLazyGetBalanceInfoQuery
