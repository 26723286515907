import { rtkApi } from 'shared/api/rtkApi'
import { TariffListResponse, TariffSetRequest, TariffSetResponse } from '../model/types/selectTariff'

const selectTariffApi = rtkApi.injectEndpoints({
    endpoints: (build) => ({
        getTariffList: build.query<TariffListResponse, void>({
            query: () => ({
                url: '/tarif/list',
            }),
            transformResponse: (response: TariffListResponse) => {
                for (let i = 0; i < response.result.length; i++) {
                    response.result[i].description_arg = response.result[i].description
                        .split('\r\n')
                        .map((item) => item.replace('•', '').trim())
                }

                return response
            },
        }),
        setTariff: build.mutation<TariffSetResponse, TariffSetRequest>({
            query: (body) => ({
                url: '/user/subscription_set',
                method: 'POST',
                body,
            }),
            invalidatesTags: [{ type: 'subscription', id: 'subscriptionInfo' }],
        }),
    }),
})

export const useLazyGetTariffList = selectTariffApi.useLazyGetTariffListQuery
export const useGetTariffList = selectTariffApi.useGetTariffListQuery
export const useSetTariff = selectTariffApi.useSetTariffMutation
