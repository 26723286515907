import classNames from 'classnames'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Text } from '@mantine/core'
import { useStyles } from './ArgDescriptionRight.styles'

interface ArgDescriptionRightProps {
    className?: string
    data?: string[]
    leftText: string
    dataDouble?: string[][]
}

export const ArgDescriptionRight: FC<ArgDescriptionRightProps> = (props) => {
    const { className, leftText, data, dataDouble } = props

    const { classes } = useStyles()
    const { t } = useTranslation()

    return (
        <Grid>
            <Grid.Col span={'content'}>
                <Text color="gray.7">{leftText}</Text>
            </Grid.Col>
            {dataDouble ? (
                <Grid.Col span={'auto'}>
                    {dataDouble &&
                        dataDouble.map((innerArray, index) => (
                            <div key={index}>
                                {innerArray.map((str, innerIndex) => (
                                    <Text component="span" key={innerIndex}>
                                        {`${str} `}
                                    </Text>
                                ))}
                            </div>
                        ))}
                </Grid.Col>
            ) : (
                <Grid.Col span={'auto'}>{data && data.map((str, index) => <Text key={index}>{str}</Text>)}</Grid.Col>
            )}
        </Grid>
    )
}
