import { createStyles } from '@mantine/core'

export const useStyles = createStyles((theme) => ({
    verificationFormButton: {
        background:
            'linear-gradient(153deg, rgba(53,255,85,1) 0%, rgba(23,143,191,1) 30%, rgba(35,211,158,1) 52%, rgba(23,143,191,1) 71%, rgba(53,255,85,1) 96%)',
        backgroundSize: '200% 200%',
        animation: 'background 9s infinite',
        '@keyframes background': {
            '0%': {
                backgroundPosition: '100% 0',
            },
            '50%': {
                backgroundPosition: '0 100%',
            },
            '100%': {
                backgroundPosition: '100% 0',
            },
        },
    },
}))
