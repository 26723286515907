import classNames from 'classnames'
import { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { Anchor } from '@mantine/core'
import { useStyles } from './AppLink.styles'

interface AppLinkProps {
    className?: string
    children: ReactNode
    to: string
}

export const AppLink = (props: AppLinkProps) => {
    const { className, children, to } = props

    const { classes } = useStyles()

    return (
        <Anchor className={classNames(classes.appLink, {}, [className])} href={to}>
            {children}
        </Anchor>
    )
}
