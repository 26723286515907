import { useState, useEffect } from 'react'

export const useCurrentReleaseUid = () => {
    const [releaseUid, setReleaseUid] = useState(window.location.hash.substring(1))

    useEffect(() => {
        const hashChangeHandler = () => {
            setReleaseUid(window.location.hash.substring(1))
        }

        window.addEventListener('hashchange', hashChangeHandler)

        return () => {
            window.removeEventListener('hashchange', hashChangeHandler)
        }
    }, [])

    return releaseUid
}
