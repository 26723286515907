import classNames from 'classnames'
import { InternetConnectionChecker } from 'shared/ui/InternetConnectionChecker/InternetConnectionChecker'
import { GlobalAlertModal } from 'widgets/GlobalAlertModal/ui/GlobalAlertModal'
import { GlobalStyle } from './styles/GlobalStyle'
import { ThemeProvider } from './styles/ThemeProvider'
import { AppRouter } from './providers/router'
import { NavigationProvider } from './providers/NavigationProvider'

const App = () => (
    <ThemeProvider>
        <GlobalStyle />
        <div className={classNames('app', {}, [])}>
            {/* Глобальное модальное окно с ошибками, для взаимодействия используйте redux */}
            <GlobalAlertModal />
            <div className="content-page">
                <NavigationProvider />
                <InternetConnectionChecker>
                    <AppRouter />
                </InternetConnectionChecker>
            </div>
        </div>
    </ThemeProvider>
)

export default App
