import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Box, Grid, ScrollArea, Title, Text, LoadingOverlay, Divider } from '@mantine/core'
import { BorderWindow } from 'shared/ui/BorderWindow/BorderWindow'
import { EmptyStatList } from 'shared/ui/EmptyStatList/EmptyStatList'
import { useResponsive } from 'shared/hooks/useResponsive/useResponsive'
import { getChartReleaseTopData } from '../../model/selectors/getChartReleaseTopData/getChartReleaseTopData'
import { useStyles } from './StatChartReleasesTop.styles'
import { StatChartReleasesTopItem } from '../StatChartReleasesTopItem/StatChartReleasesTopItem'
import { getStatisticsLoading } from '../../model/selectors/getStatisticsLoading/getStatisticsLoading'

interface StatChartReleasesTopProps {
    className?: string
}

export const StatChartReleasesTop: FC<StatChartReleasesTopProps> = (props) => {
    const { className } = props

    const { classes } = useStyles()
    const { t } = useTranslation()

    const chartData = useSelector(getChartReleaseTopData)
    const chartLoading = useSelector(getStatisticsLoading)

    const { md } = useResponsive()

    return (
        <BorderWindow>
            <Title order={2} mb="lg">
                Топ прослушиваемых релизов
            </Title>
            <Box pos={'relative'} mih={250}>
                <LoadingOverlay visible={chartLoading} />
                {chartData.length === 0 ? (
                    <EmptyStatList />
                ) : (
                    <ScrollArea h={300} offsetScrollbars>
                        {chartData.map((release, index) => (
                            <>
                                <Grid key={index} align="center">
                                    {index === 0 && !md && (
                                        <Grid.Col span={12}>
                                            <Grid>
                                                <Grid.Col span={'content'}>
                                                    <Text color="gray">№</Text>
                                                </Grid.Col>
                                                <Grid.Col span={'content'} px={20}>
                                                    <Text color="gray">Лого</Text>
                                                </Grid.Col>
                                                <Grid.Col span={'auto'}>
                                                    <Text color="gray">Название</Text>
                                                </Grid.Col>
                                                <Grid.Col span={'auto'}>
                                                    <Text color="gray">Исполнитель</Text>
                                                </Grid.Col>
                                                <Grid.Col span={'auto'}>
                                                    <Text color="gray" ta="center">
                                                        Кол-во стримов
                                                    </Text>
                                                </Grid.Col>
                                                <Grid.Col span={'auto'}>
                                                    <Text color="gray" ta="center">
                                                        UPC
                                                    </Text>
                                                </Grid.Col>
                                            </Grid>
                                        </Grid.Col>
                                    )}
                                    <StatChartReleasesTopItem key={index} index={index} release={release} />
                                </Grid>
                                <Divider my="md" />
                            </>
                        ))}
                    </ScrollArea>
                )}
            </Box>
        </BorderWindow>
    )
}
