import { FC, useEffect, useState } from 'react'
import { Alert, Text } from '@mantine/core'
import { DatePickerInput } from '@mantine/dates'
import dayjs from 'dayjs'
import { IconCalendar } from '@tabler/icons-react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'shared/hooks/useAppDispatch/useAppDispatch'
import { InputActualDate } from '../InputActualDate/InputActualDate'
import { useStyles } from './StandardReleaseDate.styles'
import { getPublicDate } from '../../models/selectors/getPublicDate'
import { updateDateActions } from '../../models/slices/updateDateSlice'
import { after14DayDate } from '../../models/consts/updateDateConsts'
import { UpdateDatePrevInfo } from '../../models/types/updateDate'

interface StandardReleaseDateProps {
    infoRelease: UpdateDatePrevInfo
}

export const StandardReleaseDate: FC<StandardReleaseDateProps> = (props) => {
    const { infoRelease } = props
    const { classes } = useStyles()
    const dispatch = useAppDispatch()

    const [textInfo, setTextInfo] = useState<string>('')
    const [minPublicDate, setMinPublicDate] = useState<Date>(after14DayDate)
    const updatePublicDate = (value: Date | null) => {
        if (!value) return
        dispatch(updateDateActions.reducerPublicDate(dayjs(value).format('YYYY-MM-DD')))
    }

    const publicDate = useSelector(getPublicDate)

    const calculateNewPublicationDate = (publicationDate: Date) => {
        const today = dayjs().startOf('day')
        const pubDate = dayjs(publicationDate).startOf('day')
        const text = `К сожалению, осталось слишком мало времени для публикации ${dayjs(publicationDate).format(
            'DD.MM.YYYY',
        )}. Необходимо выбрать более позднюю дату релиза.`
        // В этом блоке проверяем, если сегодняшняя дата больше или равна дате публикации
        if (today.isAfter(pubDate) || today.isSame(pubDate)) {
            const newPubDate = today.add(4, 'days')

            // Ваш текстовый вывод
            setTextInfo(text)

            return newPubDate.toDate()
        }

        const daysDifference = pubDate.diff(today, 'day')

        if (daysDifference >= 1 && daysDifference <= 4) {
            const daysToAdd = 4 - daysDifference
            const newPubDate = pubDate.add(daysToAdd, 'days')

            // Ваш текстовый вывод
            setTextInfo(text)

            return newPubDate.toDate()
        }

        return pubDate.toDate()
    }

    useEffect(() => {
        if (infoRelease.status === 2) {
            const currentDate = dayjs(infoRelease.public_date).toDate()
            updatePublicDate(calculateNewPublicationDate(currentDate))
            setMinPublicDate(calculateNewPublicationDate(currentDate))
        } else {
            updatePublicDate(after14DayDate)
        }
    }, [infoRelease])

    return (
        <div className={classes.standardReleaseDate}>
            {textInfo.length > 0 && (
                <Alert
                    color="blue"
                    variant="light"
                    title="Информация"
                    withCloseButton
                    onClose={() => {
                        setTextInfo('')
                    }}
                    mb={'md'}
                >
                    {textInfo}
                </Alert>
            )}
            <Text mb="md">Стандартно, не менее 14 дней с сегодняшней даты</Text>
            <DatePickerInput
                value={dayjs(publicDate).toDate()}
                onChange={updatePublicDate}
                label="Дата публикации"
                placeholder="Выберите дату"
                maw={200}
                mb="md"
                valueFormat="DD.MM.YYYY"
                minDate={minPublicDate}
                icon={<IconCalendar size="1.1rem" stroke={1.5} />}
            />
            <InputActualDate />
            <Text color="gray" mb="xl">
                Если релиз уже вышел в свет и необходимо отобразить дату релиза. Если релиз новый, оставьте поле пустым.
            </Text>
            <Text color="gray" ta="justify">
                Для подачи заявки в официальные плейлисты и подборки музыкальных площадок необходимо загружать релиз не
                ранее, чем за 20 дней. Тогда в разделе «мои релизы» в строчке с релизом станут доступны анкеты для
                подачи промо.
            </Text>
        </div>
    )
}
