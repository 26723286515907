import classNames from 'classnames'
import { FC } from 'react'
import { Loader } from '@mantine/core'
import { useStyles } from './PageLoader.styles'

interface PageLoaderProps {
    className?: string
}

export const PageLoader: FC<PageLoaderProps> = (props) => {
    const { className } = props

    const { classes } = useStyles()

    return (
        <div className={classNames(classes.pageLoader, {}, [className])}>
            <Loader size="xl" />
        </div>
    )
}
