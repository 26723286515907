import { useMediaQuery } from '@mantine/hooks'

export const useResponsive = () => {
    const xs = useMediaQuery('(max-width: 576px)')
    const sm = useMediaQuery('(max-width: 768px)')
    const lg = useMediaQuery('(max-width: 1200px)')
    const md = useMediaQuery('(max-width: 992px)')
    const xl = useMediaQuery('(max-width: 1408px)')

    return { xs, sm, md, lg, xl }
}
