import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal, Text } from '@mantine/core'
import { useSelector } from 'react-redux'
import { getTariffModalOpen } from 'features/selectTariff/model/selectors/getTariffModalOpen'
import { useAppDispatch } from 'shared/hooks/useAppDispatch/useAppDispatch'
import { selectTariffActions } from 'features/selectTariff/model/slices/selectTariffSlice'
import { useStyles } from './TariffModal.styles'
import { TariffSelector } from '../TariffSelector/TariffSelector'

interface TariffModalProps {}

export const TariffModal: FC<TariffModalProps> = (props) => {
    const {} = props

    const { classes } = useStyles()
    const { t } = useTranslation()

    const dispatch = useAppDispatch()
    const IsTariffModalOpen = useSelector(getTariffModalOpen)

    const onClose = () => {
        dispatch(selectTariffActions.reducerTariffModal({ isOpen: false, message: '', startIndex: 0 }))
    }

    return (
        <Modal
            opened={IsTariffModalOpen}
            onClose={onClose}
            centered
            size={990}
            h="auto"
            title={
                <Text fw={600} fz={20}>
                    Выберите тариф
                </Text>
            }
        >
            <TariffSelector />
        </Modal>
    )
}
