import { combineReducers } from '@reduxjs/toolkit'
import { rtkApi } from 'shared/api/rtkApi'
import { userSlice } from 'entities/User'
import { releaseSlice } from 'entities/Release'
import { financeSlice } from 'entities/Finance'
import { statisticsSlice } from 'entities/Statistics'
import { globalAlertModalSlice } from 'widgets/GlobalAlertModal'
import { selectTariffSlice } from 'features/selectTariff'
import { fastPaymentCardsSlice } from 'features/fastPaymentCards'
import { paymentSlice } from 'features/paymentGateway'
import { updateDateSlice } from 'features/addingReleaseUpdateDate'
import { StateScheme } from './StateScheme'

const rootReducer = combineReducers({
    user: userSlice.reducer,
    release: releaseSlice.reducer,
    finance: financeSlice.reducer,
    statistics: statisticsSlice.reducer,
    globalAlertModal: globalAlertModalSlice.reducer,
    payment: paymentSlice.reducer,
    selectTariff: selectTariffSlice.reducer,
    fastPaymentCards: fastPaymentCardsSlice.reducer,
    updateDate: updateDateSlice.reducer,
    [rtkApi.reducerPath]: rtkApi.reducer,
})

export type RootState = StateScheme
export default rootReducer
