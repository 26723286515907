import { FC, ReactNode } from 'react'
import { MantineProvider } from '@mantine/core'
import { Notifications } from '@mantine/notifications'

interface ThemeProviderProps {
    children: ReactNode
}

export const ThemeProvider: FC<ThemeProviderProps> = (props) => {
    const { children } = props
    return (
        <MantineProvider
            withNormalizeCSS
            theme={{
                fontFamily: 'Montserrat, sans-serif',
                colors: {
                    primary: [
                        'rgba(28, 181, 158, 0.15)',
                        '#49FFFF',
                        '#00FFFF',
                        '#00FFE1',
                        '#137567',
                        '#00A98C',
                        '#1DB6A0',
                        '#008770',
                        '#006C59',
                        '#005648',
                    ],
                },
                fontSizes: {
                    xs: '0.6rem',
                    sm: '0.75rem',
                    md: '1rem',
                    lg: '1.1rem',
                    xl: '1.3rem',
                },
                primaryColor: 'primary',
                headings: {
                    fontWeight: 600,
                    fontFamily: 'Montserrat, sans-serif',
                    sizes: {
                        h2: {
                            fontSize: '1.125rem',
                            lineHeight: '1.5rem',
                        },
                        h3: {
                            fontSize: '2rem',
                            lineHeight: '2.375rem',
                        },
                    },
                },
                components: {
                    Input: {
                        styles: (theme) => ({
                            input: { fontSize: theme.fontSizes.md },
                        }),
                    },
                },
            }}
        >
            <Notifications />
            {children}
        </MantineProvider>
    )
}
