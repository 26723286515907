import { createStyles } from '@mantine/core'

export const useStyles = createStyles((theme) => ({
    borderWindow: {
        borderRadius: 8,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: theme.colors.gray[4],
        padding: 20,
        height: '100%',
        width: '100%',
    },
}))
