import { Card, List, Text } from '@mantine/core'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { TariffList } from '../../model/types/selectTariff'

interface TariffItemCardProps {
    className?: string
    tariff: TariffList
}

export const TariffItemCard: FC<TariffItemCardProps> = (props) => {
    const { className, tariff } = props

    const { t } = useTranslation()

    return (
        <Card shadow="md" withBorder h={'100%'}>
            <Text fw={600}>{tariff.title}</Text>
            <List my={20}>
                {tariff.description_arg.map((description: string, index: number) => (
                    <List.Item key={index}>{description}</List.Item>
                ))}
            </List>
        </Card>
    )
}
