import { Navigate } from 'react-router-dom'
import { ProfilePage } from 'pages/ProfilePage'
import type { RouteProps } from 'react-router-dom'
import { ReleaseListPage } from 'pages/ReleaseListPage'
import { StatisticsPage } from 'pages/StatisticsPage'
import { BalancePage } from 'pages/BalancePage'
import { FinancialAnalyticsPage } from 'pages/FinancialAnalyticsPage'
import { FaqPage } from 'pages/FaqPage'
import { AddingReleaseStep3Page } from 'pages/AddingReleaseStep3Page'
import { ReleaseDetailPage } from 'pages/ReleaseDetailPage'
import { AddingReleaseFinishPage } from 'pages/AddingReleaseFinishPage'
import { AddingReleaseStep1Page } from 'pages/AddingReleaseStep1Page'
import { AddingReleaseStartPage } from 'pages/AddingReleaseStartPage'
import { AddingReleaseStep2Page } from 'pages/AddingReleaseStep2Page'

export enum AppRoutes {
    MAIN = 'main',
    PROFILE = 'profile',
    YTNT = 'ytnt',
    RELEASE_LIST = 'release_list',
    STATISTICS = 'statistics',
    // BALANCE = 'balance',
    FINANCIAL_ANALYTICS = 'financial_analytics',
    // FAQ = 'faq',
    ADDING_RELEASE_STEP1 = 'adding_release_step1',
    ADDING_RELEASE_STEP2 = 'adding_release_step2',
    ADDING_RELEASE_START = 'adding_release_start',
    ADDING_RELEASE_EDIT = 'adding_release_edit',
    ADDING_RELEASE_STEP3 = 'adding_release_step3',
    ADDING_RELEASE_FINISH = 'adding_release_finish',
    DETAIL_RELEASE = 'detail_release',
}

export const RoutePath: Record<AppRoutes, string> = {
    [AppRoutes.MAIN]: '/',
    [AppRoutes.YTNT]: '/ytnt',
    [AppRoutes.PROFILE]: '/ytnt/profile',
    [AppRoutes.RELEASE_LIST]: '/ytnt/release',
    [AppRoutes.STATISTICS]: '/ytnt/statistics',
    // [AppRoutes.BALANCE]: '/ytn/balance',
    [AppRoutes.FINANCIAL_ANALYTICS]: '/ytnt/financial-analytics',
    // [AppRoutes.FAQ]: '/ytn/faq',
    [AppRoutes.ADDING_RELEASE_STEP1]: '/ytnt/release/edit/step1',
    [AppRoutes.ADDING_RELEASE_STEP2]: '/ytnt/release/edit/step2',
    [AppRoutes.ADDING_RELEASE_EDIT]: '/ytnt/release/edit',
    [AppRoutes.ADDING_RELEASE_START]: '/ytnt/release/edit/start',
    [AppRoutes.ADDING_RELEASE_STEP3]: '/ytnt/release/edit/step3',
    [AppRoutes.ADDING_RELEASE_FINISH]: '/ytnt/release/edit/finish-publication',
    [AppRoutes.DETAIL_RELEASE]: '/ytnt/release/detail',
}

export const routeConfig: Record<AppRoutes, RouteProps> = {
    [AppRoutes.MAIN]: {
        path: RoutePath[AppRoutes.MAIN],
        element: <Navigate to={RoutePath.profile} />,
    },
    [AppRoutes.YTNT]: {
        path: RoutePath[AppRoutes.YTNT],
        element: <Navigate to={RoutePath.profile} />,
    },
    [AppRoutes.PROFILE]: {
        path: RoutePath[AppRoutes.PROFILE],
        element: <ProfilePage />,
    },
    [AppRoutes.ADDING_RELEASE_EDIT]: {
        path: RoutePath[AppRoutes.ADDING_RELEASE_EDIT],
        element: <Navigate to={'/ytnt/release/edit/step3'} />,
    },
    [AppRoutes.RELEASE_LIST]: {
        path: RoutePath[AppRoutes.RELEASE_LIST],
        element: <ReleaseListPage />,
    },
    [AppRoutes.STATISTICS]: {
        path: RoutePath[AppRoutes.STATISTICS],
        element: <StatisticsPage />,
    },
    // [AppRoutes.BALANCE]: {
    //     path: RoutePath[AppRoutes.BALANCE],
    //     element: <BalancePage />,
    // },
    [AppRoutes.FINANCIAL_ANALYTICS]: {
        path: RoutePath[AppRoutes.FINANCIAL_ANALYTICS],
        element: <FinancialAnalyticsPage />,
    },
    // [AppRoutes.FAQ]: {
    //     path: RoutePath[AppRoutes.FAQ],
    //     element: <FaqPage />,
    // },
    [AppRoutes.ADDING_RELEASE_STEP1]: {
        path: RoutePath[AppRoutes.ADDING_RELEASE_STEP1],
        element: <AddingReleaseStep1Page />,
    },
    [AppRoutes.ADDING_RELEASE_STEP2]: {
        path: RoutePath[AppRoutes.ADDING_RELEASE_STEP2],
        element: <AddingReleaseStep2Page />,
    },
    [AppRoutes.ADDING_RELEASE_START]: {
        path: RoutePath[AppRoutes.ADDING_RELEASE_START],
        element: <AddingReleaseStartPage />,
    },
    [AppRoutes.ADDING_RELEASE_STEP3]: {
        path: RoutePath[AppRoutes.ADDING_RELEASE_STEP3],
        element: <AddingReleaseStep3Page />,
    },
    [AppRoutes.ADDING_RELEASE_FINISH]: {
        path: RoutePath[AppRoutes.ADDING_RELEASE_FINISH],
        element: <AddingReleaseFinishPage />,
    },
    [AppRoutes.DETAIL_RELEASE]: {
        path: RoutePath[AppRoutes.DETAIL_RELEASE],
        element: <ReleaseDetailPage />,
    },
}
