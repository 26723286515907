import { Group, Button } from '@mantine/core'
import { FC, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { useStyles } from './NavigationButtons.styles'

interface NavigationButtonsProps {
    className?: string
    onPrev: () => void
    onNext?: () => void
    textPrev: string
    textNext: string
    type?: 'button' | 'submit' | 'reset'
    isLoading?: boolean
    isPrevDisabled?: boolean
    isNextDisabled?: boolean
    mt?: number
    AddTrackButton?: ReactNode
}

export const NavigationButtons: FC<NavigationButtonsProps> = (props) => {
    const { onPrev, isLoading, onNext, type, isPrevDisabled, isNextDisabled, textNext, textPrev, mt, AddTrackButton } =
        props

    const { classes } = useStyles()
    const { t } = useTranslation()

    return (
        <Group position="right" className={classes.navigationButtons} mt={mt}>
            <Button
                fullWidth
                variant="outline"
                color="dark"
                onClick={onPrev}
                className={classes.prevBtn}
                disabled={isPrevDisabled}
            >
                {textPrev}
            </Button>
            {AddTrackButton}
            <Button
                fullWidth
                color="dark"
                type={type}
                loading={isLoading}
                onClick={onNext}
                className={classes.nextBtn}
                disabled={isNextDisabled}
            >
                {textNext}
            </Button>
        </Group>
    )
}
