import { useMemo } from 'react'
import { PaymentData } from '../model/types/subscription'

export function usePaymentStatus(data: PaymentData): 'error' | 'nextPaid' | 'paid' | 'noPayment' {
    const { is_pay, next_is_pay } = data

    return useMemo(() => {
        if (next_is_pay === true && (is_pay === false || is_pay === null)) {
            return 'error'
        } else if (next_is_pay) {
            return 'nextPaid'
        } else if (is_pay) {
            return 'paid'
        }
        return 'noPayment'
    }, [is_pay, next_is_pay])
}
