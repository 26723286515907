import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { RootState } from 'app/providers/StoreProvider/config/rootReducer'

export const updateChartCountry = createAsyncThunk<any, { start: string; end: string }, { state: RootState }>(
    'finance/updateChartCountry',
    async ({ start, end }, thunkAPI) => {
        const formData = new FormData()
        formData.append('date_from', start)
        formData.append('date_to', end)

        const { user } = thunkAPI.getState()
        const token = localStorage.getItem('token')

        try {
            const response = await axios.post('https://api.yourtunes.net/api/v2/fin-analitic/country', formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })

            return response.data.result.series.data
        } catch (e) {
            console.log(e)
        }
    },
)
