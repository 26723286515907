import { Card, List, Anchor, Text, Button, Group, Grid } from '@mantine/core'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useResponsive } from 'shared/hooks/useResponsive/useResponsive'
import { IconBrandTelegram, IconMail } from '@tabler/icons-react'

interface ReleaseListEmptyProps {
    className?: string
}

export const ReleaseListEmpty: FC<ReleaseListEmptyProps> = (props) => {
    const { className } = props

    const { t } = useTranslation()

    const { xs } = useResponsive()

    return (
        <div>
            <Card variant="light" color="teal.3" maw={900} p={xs ? 20 : 40} mx={'auto'}>
                <Text fz="lg" fw={600} mb="lg">
                    Для того чтобы опубликовать релиз на цифровых площадках:
                </Text>
                <List spacing="md" mb="md" type="ordered">
                    <List.Item>
                        <Text fw={500}>Нажмите кнопку «Добавить релиз»</Text>
                    </List.Item>
                    <List.Item>
                        <Text fw={500}>Пройдите все этапы и заполните необходимую информацию</Text>
                    </List.Item>
                    <List.Item>
                        <Text fw={500}>Выберите день релиза и отправьте его на модерацию</Text>
                    </List.Item>
                    <List.Item>
                        <Text fw={500}>Ход публикации можно отслеживать по статусу релиза</Text>
                    </List.Item>
                </List>
                <Text fz="md" fw={500} span>
                    Если у вас возникнут вопросы по работе сервиса, то в разделе «помощь» вы моежете обратиться в службу
                    поддержки.
                </Text>
                <Text ta="center" my={'xl'}>
                    А так же можно обращаться на почту или в чат пользователей
                </Text>
                <Grid>
                    <Grid.Col span={12} xs={6}>
                        <Button
                            leftIcon={<IconMail stroke={1.3} />}
                            component={'a'}
                            href={'mailto:support@yourtunes.ru'}
                            fullWidth
                            target={'_blank'}
                            variant="outline"
                            c={'black'}
                        >
                            support@yourtunes.ru
                        </Button>
                    </Grid.Col>
                    <Grid.Col span={12} xs={6}>
                        <Button
                            leftIcon={<IconBrandTelegram stroke={1.3} />}
                            component={'a'}
                            href={'https://t.me/YOURUTNESchat'}
                            target={'_blank'}
                            variant="outline"
                            c={'black'}
                            fullWidth
                        >
                            Чат пользователей
                        </Button>
                    </Grid.Col>
                </Grid>
            </Card>
        </div>
    )
}
