import { rtkApi } from 'shared/api/rtkApi'
import {
    QuickReleaseFastPayResponse,
    QuickReleasePayRequest,
    QuickReleasePayResponse,
    SubscriptionFastPayResponse,
    SubscriptionPayRequest,
    SubscriptionPayResponse,
    UserFromNotRussia,
    QuickReleaseFastPayRequest,
} from '../model/types/payment'

const paymentApi = rtkApi.injectEndpoints({
    endpoints: (build) => ({
        payIsNotRussia: build.mutation<any, UserFromNotRussia>({
            query: (body) => ({
                url: '/pay/order_not_russia',
                method: 'POST',
                body,
            }),
            invalidatesTags: [{ type: 'subscription', id: 'subscriptionInfo' }],
        }),
        paySubscription: build.mutation<SubscriptionPayResponse, SubscriptionPayRequest>({
            query: (body) => ({
                url: '/user/subscription_pay',
                method: 'POST',
                body,
            }),
            invalidatesTags: [
                { type: 'subscription', id: 'subscriptionInfo' },
                { type: 'balance', id: 'info' },
            ],
        }),
        payFastSubscription: build.mutation<SubscriptionFastPayResponse, SubscriptionPayRequest>({
            query: (body) => ({
                url: '/user/subscription_pay_fast',
                method: 'POST',
                body,
            }),
            invalidatesTags: [
                { type: 'subscription', id: 'subscriptionInfo' },
                { type: 'balance', id: 'info' },
            ],
        }),
        payQuickRelease: build.mutation<QuickReleasePayResponse, QuickReleasePayRequest>({
            query: (body) => ({
                url: '/release/pay',
                method: 'POST',
                body,
            }),
            invalidatesTags: [
                { type: 'releaseInfo', id: 'info' },
                { type: 'balance', id: 'info' },
            ],
        }),
        payFastQuickRelease: build.mutation<QuickReleaseFastPayResponse, QuickReleaseFastPayRequest>({
            query: (body) => ({
                url: '/release/pay_fast',
                method: 'POST',
                body,
            }),
            invalidatesTags: [
                { type: 'releaseInfo', id: 'info' },
                { type: 'balance', id: 'info' },
            ],
        }),
    }),
})

export const usePayIsNotRussia = paymentApi.usePayIsNotRussiaMutation
export const usePayFastSubscription = paymentApi.usePayFastSubscriptionMutation
export const usePaySubscription = paymentApi.usePaySubscriptionMutation
export const usePayQuickRelease = paymentApi.usePayQuickReleaseMutation
export const usePayFastQuickRelease = paymentApi.usePayFastQuickReleaseMutation
