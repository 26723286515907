import { createStyles } from '@mantine/core'

export const useStyles = createStyles((theme) => ({
    releaseDetail: {},
    image: {
        '& .mantine-Image-placeholder': {
            background: theme.colors.gray[4],
        },
        maxWidth: 364,
        maxHeight: 364,
        marginBottom: 30,
        [theme.fn.smallerThan('xs')]: {
            maxWidth: 'none',
            maxHeight: 'none',
        },
    },
    button: {
        maxWidth: 200,
        [theme.fn.smallerThan('sm')]: {
            maxWidth: 'none',
        },
    },
}))
