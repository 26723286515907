import { fetchUserToken } from 'entities/User/services/fetchUserToken'
import { useState, useEffect } from 'react'

interface AuthWpProviderProps {
    children: JSX.Element
}

export function AuthWpProvider({ children }: AuthWpProviderProps) {
    const [hasToken, setHasToken] = useState(false)
    const localToken = localStorage.getItem('token')

    useEffect(() => {
        if (window.location.origin === 'http://localhost:3000') {
            setHasToken(true)
            return
        }

        if (!localToken) {
            ;(async () => {
                try {
                    await fetchUserToken()
                    setHasToken(true)
                } catch (e) {
                    window.location.href = 'https://yourtunes.net/authorization'
                    // console.log('ОШИБКА! ПОЛУЧЕНИЯ ЛОКАЛЬНОГО ТОКЕНА!')
                }
            })()
        } else {
            setHasToken(true)
        }
    }, [])

    if (!hasToken) {
        console.log('Нет токена!')
        return null
    }

    return children
}
