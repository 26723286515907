import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal, Alert, Text, Button } from '@mantine/core'
import {
    IconAlertSquareRoundedFilled,
    IconDirectionSignFilled,
    IconInfoSquareRoundedFilled,
    IconSquareCheckFilled,
} from '@tabler/icons-react'
import { useSelector } from 'react-redux'
import { getAlertDetails } from '../model/selectors/getAlertDetails'
import { useGlobalAlertModal } from '../hooks/useGlobalAlertModal'

export const GlobalAlertModal: FC = () => {
    const { t } = useTranslation()

    const alertDetails = useSelector(getAlertDetails)

    const { closeGlobalAlert } = useGlobalAlertModal()

    const icons = {
        error: <IconAlertSquareRoundedFilled />,
        success: <IconSquareCheckFilled />,
        info: <IconInfoSquareRoundedFilled />,
    }

    const colors = {
        error: 'red',
        success: 'green',
        info: 'blue',
    }

    const goToLink = (href: string) => {
        closeGlobalAlert()
        window.location.href = href
    }

    return (
        <Modal
            opened={!!alertDetails.message}
            onClose={closeGlobalAlert}
            padding={0}
            withCloseButton={false}
            centered
            transitionProps={{ transition: 'rotate-left' }}
            closeOnClickOutside={false}
            size={'md'}
            zIndex={9999999}
        >
            <Alert
                icon={
                    <Text fz={30} pt={12} color={`${colors[alertDetails.type]}.8`}>
                        {icons[alertDetails.type]}
                    </Text>
                }
                title={
                    <Text fz={'xl'} pb={10} fw={900} color={`${colors[alertDetails.type]}.8`}>
                        {alertDetails.title}
                    </Text>
                }
                color={colors[alertDetails.type]}
                p={30}
                withCloseButton={false}
                onClose={closeGlobalAlert}
            >
                <Text ta="justify" fz={'md'}>
                    {alertDetails.message}
                </Text>
                {alertDetails.link && (
                    <Button
                        fullWidth
                        onClick={alertDetails.link ? () => goToLink(alertDetails.link?.href ?? '') : undefined}
                        rightIcon={<IconDirectionSignFilled />}
                        mt={20}
                        color="dark"
                        variant="outline"
                    >
                        {alertDetails.link.text}
                    </Button>
                )}
                <Button onClick={closeGlobalAlert} fullWidth mt={30} color={`${colors[alertDetails.type]}.8`}>
                    Закрыть
                </Button>
            </Alert>
        </Modal>
    )
}
