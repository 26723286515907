import { Avatar, Skeleton } from '@mantine/core'
import { useGetUserInfo } from 'entities/User/api/userApi'

interface UserAvatarProps {
    className?: string
}

export const UserAvatar = (props: UserAvatarProps) => {
    const { className } = props

    const { data, isLoading } = useGetUserInfo()

    return (
        <>
            {isLoading ? (
                <Skeleton className={className} circle />
            ) : (
                <Avatar className={className} src={data?.result.ava_link} />
            )}
        </>
    )
}
