import { useTranslation } from 'react-i18next'
import { Stack, Checkbox, Group, Anchor, Text, Alert } from '@mantine/core'
import { TextDescriptionRight } from 'shared/ui/TextDescriptionRight/TextDescriptionRight'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'shared/hooks/useAppDispatch/useAppDispatch'
import { IconInfoSquareFilled } from '@tabler/icons-react'
import { getPaymentPrice } from '../../model/selectors/getPaymentPrice'
import { getPaymentProduct } from '../../model/selectors/getPaymentProduct'
import { getPaymentPeriod } from '../../model/selectors/getPaymentPeriod'
import { getPaymentAcceptOffer } from '../../model/selectors/getPaymentAcceptOffer'
import { paymentActions } from '../../model/slices/paymentSlice'
import { PaymentButton } from '../PaymentButton/PaymentButton'
import { getPaymentType } from '../../model/selectors/getPaymentType'

interface PaymentAltProps {}

export const PaymentAlt = () => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch()

    const acceptOffer = useSelector(getPaymentAcceptOffer)
    const product = useSelector(getPaymentProduct)
    const period = useSelector(getPaymentPeriod)
    const price = useSelector(getPaymentPrice)
    const paymentType = useSelector(getPaymentType)
    const handleAcceptOffer = (value: boolean) => {
        dispatch(paymentActions.reducerAcceptOffer(value))
    }

    return (
        <Stack spacing="xl">
            {paymentType === 'subscription' && (
                <Alert title="Внимание!" icon={<IconInfoSquareFilled />} color="blue">
                    Рекомендуем приобретать годовые подписки, так как автоплатеж по подписке доступен только для
                    Российских карт
                </Alert>
            )}
            <TextDescriptionRight leftText={product.title} rightText={product.name} />
            {period.title && <TextDescriptionRight leftText={period.title} rightText={period.name} />}
            <TextDescriptionRight leftText={price.title} rightText={`${price.name} руб.`} />
            <Group position="apart">
                <Text fw={600} fz="xl">
                    Итого к оплате:
                </Text>
                <Text fw={600} fz="xl">
                    {price.name} руб.
                </Text>
            </Group>
            <Checkbox
                checked={acceptOffer}
                onChange={(event) => handleAcceptOffer(event.currentTarget.checked)}
                radius="xs"
                label={
                    <>
                        Согласен с условиями{' '}
                        <Anchor href="https://yourtunes.net/usloviya-oferty" target="_blank">
                            оферты
                        </Anchor>
                    </>
                }
            />
            <PaymentButton buttonType="alt" />
        </Stack>
    )
}
