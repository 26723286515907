interface RequireAuthProps {
    children: JSX.Element
}

export function RequireAuth({ children }: RequireAuthProps) {
    const token = localStorage.getItem('token')

    if (window.location.origin === 'http://localhost:3000') return children

    if (!token) {
        window.location.href = 'https://yourtunes.net/'
    }

    return children
}
