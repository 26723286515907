import { TariffList } from '../types/selectTariff'

type YearlyTariffInfo = {
    id: string
    name: string
    price: string
}

export const getYearlyTariffInfo = (data: TariffList[], monthTariff: TariffList): YearlyTariffInfo | null => {
    const yearlyTariff = data.find((tariff) => tariff.count === 12 && tariff.title === monthTariff.title)

    if (yearlyTariff) {
        return {
            id: yearlyTariff.id.toString(),
            name: yearlyTariff.name,
            price: yearlyTariff.price,
        }
    }

    return null
}
