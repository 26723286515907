export enum ReleaseType {
    SINGLE = 'single',
    CLIP = 'clip',
    RINGTONE = 'ringtone',
    ALBUM = 'album',
}

export const releaseStatus: { [key: number]: string } = {
    1: 'Готов к выпуску',
    2: 'Есть ошибки',
    3: 'Опубликован',
    4: 'На модерации',
    5: 'На проверке',
    6: 'Черновик',
    7: 'Удален',
    8: 'Отклонен',
}