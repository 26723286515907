import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Center, Grid, Text } from '@mantine/core'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { useSelector } from 'react-redux'
import { getChartMonthData } from 'entities/Finance/model/selectors/getChartMonthData/getChartMonthData'
import { BorderWindow } from 'shared/ui/BorderWindow/BorderWindow'
import { getStartCalendar } from 'entities/Finance/model/selectors/getStartCalendar/getStartCalendar'
import { getEndCalendar } from 'entities/Finance/model/selectors/getEndCalendar/getEndCalendar'
import dayjs from 'dayjs'
import { useStyles } from './FinanceMonth.styles'

import 'dayjs/locale/ru'
interface FinanceMonthProps {
    className?: string
}

export const FinanceMonth: FC<FinanceMonthProps> = (props) => {
    const { className } = props

    const { classes } = useStyles()
    const { t } = useTranslation()

    const chartMonthData = useSelector(getChartMonthData)
    const startCalendar = useSelector(getStartCalendar)
    const endCalendar = useSelector(getEndCalendar)

    const [options, setOptions] = useState<Highcharts.Options>({
        chart: {
            type: 'column',
            plotBackgroundColor: '#f8f9fa',
            backgroundColor: '#f8f9fa',
        },
        title: {
            text: undefined,
        },
        xAxis: {
            categories: [],
        },
        yAxis: {
            labels: {
                enabled: false,
            },
            min: 0,
            title: {
                text: undefined,
            },
            stackLabels: {
                enabled: true,
                formatter: function () {
                    return Highcharts.numberFormat(this.total, 0) + ' ₽'
                },
                style: {
                    fontWeight: 'normal',
                    fontSize: '12px',
                    color: 'black',
                    textOutline: 'none',
                },
            },
        },
        credits: {
            enabled: false, // Убрать кредитную ссылку
        },
        tooltip: {
            useHTML: true,
            formatter: function () {
                var tooltipString = '<table>'
                if (this.points) {
                    var points = [...this.points] // создаем копию массива points
                    points.sort((a, b) => (b.y || 0) - (a.y || 0)) // сортируем points по убыванию
                    points = points.slice(0, 8) // берем только первые 6 элементов
                    for (var i = 0; i < points.length; i++) {
                        var point = points[i]
                        if (point.y === 0) continue // если значение равно нулю, то не выводим
                        tooltipString +=
                            '<tr><td style="color:' +
                            point.color +
                            ';width: 16px;height: 16px;padding: 0;">●</td><td>' +
                            point.series.name +
                            '</td><td style="text-align: right;">' +
                            point.y +
                            ' ₽</td></tr>'
                    }
                }
                tooltipString += '</table>'
                return tooltipString
            },
            shared: true,
        },
        legend: {
            enabled: false,
        },
        plotOptions: {
            column: {
                borderRadius: 10,
                maxPointWidth: 120,
                stacking: 'normal',
                dataLabels: {
                    enabled: false,
                    color: '#000',
                    style: {
                        fontSize: '14px',
                        fontWeight: 'bold',
                        textOutline: 'none',
                    },
                    verticalAlign: 'top',
                    crop: false,
                },
            },
        },
        series: [],
    })

    useEffect(() => {
        if (chartMonthData !== undefined) {
            const newSeries = JSON.parse(JSON.stringify(chartMonthData.series))
            const newCategories = chartMonthData.categories.map((date: string) =>
                dayjs(date).locale('ru').format('MMM YYYY'),
            )
            setOptions((prevOptions) => ({
                ...prevOptions,
                xAxis: { categories: newCategories },
                series: newSeries,
            }))
        }
    }, [chartMonthData])

    return (
        <BorderWindow>
            <Grid justify="space-between">
                <Grid.Col span="auto">{`Чистый доход за период: ${dayjs(startCalendar)
                    .locale('ru')
                    .format('MMM, YY')} - ${dayjs(endCalendar).locale('ru').format('MMM, YY')}`}</Grid.Col>
                <Grid.Col span="content">
                    <Text fw={600}>{chartMonthData?.total_sum} ₽</Text>
                </Grid.Col>
            </Grid>
            <>
                {chartMonthData?.categories.length === 0 ? (
                    <Center fw={600} my={50}>
                        За текущий период график пуст
                    </Center>
                ) : (
                    <HighchartsReact highcharts={Highcharts} options={options} />
                )}
            </>
        </BorderWindow>
    )
}
