import { rtkApi } from 'shared/api/rtkApi'
import { UserSubscription } from '../model/types/subscription'

const subscriptionApi = rtkApi.injectEndpoints({
    endpoints: (build) => ({
        getUserSubscription: build.query<UserSubscription, void>({
            query: () => ({
                url: '/user/subscription',
            }),
            transformResponse(response: UserSubscription) {
                if (response.tarif_description !== null) {
                    response.tarif_description_arg = response.tarif_description
                        .split('\r\n')
                        .map((item) => item.replace('•', '').trim())
                }

                return response
            },
            providesTags: () => [{ type: 'subscription', id: 'subscriptionInfo' }],
        }),
    }),
})

export const useGetUserSubscription = subscriptionApi.useGetUserSubscriptionQuery
