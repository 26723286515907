import { createStyles } from '@mantine/core'

export const useStyles = createStyles((theme) => ({
    pageLoader: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 300,
        width: '100%',
        height: '100%',
    },
}))
