import { createStyles } from '@mantine/core'

export const useStyles = createStyles((theme) => ({
    bankCard: {
        margin: '35px 0',
        height: 60,
        width: '100%',
        border: `2px solid ${theme.colors.gray[5]}`,
        borderRadius: theme.radius.sm,
        padding: 2,
    },
    contentCard: {
        borderRadius: theme.radius.sm,
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'flex-end',
        position: 'relative',
        justifyContent: 'center',
        paddingBottom: 4,
    },
    input: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        opacity: 0,
        width: '100%',
        height: '100%',
        cursor: 'pointer',
    },
    cheked: {
        background: theme.colors.primary[6],
        '& > span': {
            color: theme.white,
        },
    },
    text: {
        marginRight: 5,
        fontWeight: 600,
        fontSize: 12,
        paddingBottom: 3,
    },
    textWrapper: {},
    buttonWrapper: {
        width: 25,
        height: 20,
        position: 'absolute',
        top: 0,
        right: 2,
    },
}))
