import { useMemo } from 'react'
import { SubscriptionData } from '../model/types/subscription'

export function useSubscriptionStatus(
    data: SubscriptionData,
): 'error' | 'subscribed' | 'nextSubscription' | 'noSubscription' {
    const { tarif, next_tarif } = data

    return useMemo(() => {
        if (tarif === null && typeof next_tarif === 'number') {
            return 'error'
        } else if (tarif !== null && tarif >= 1 && tarif <= 6) {
            return 'subscribed'
        } else if (next_tarif !== null && next_tarif >= 1 && next_tarif <= 6) {
            return 'nextSubscription'
        }
        return 'noSubscription'
    }, [tarif, next_tarif])
}
