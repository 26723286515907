import { Box, Center, Text } from '@mantine/core'
import { useTranslation } from 'react-i18next'

export const EmptyStatList = () => {
    const { t } = useTranslation()

    return (
        <Center h={200}>
            <Text>За данный период статистики нет</Text>
        </Center>
    )
}
