import { FC, useEffect, useState, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { Title, Group, Box, Text, Tooltip, useMantineTheme } from '@mantine/core'
import { IconSquareRoundedCheckFilled, IconSquareXFilled } from '@tabler/icons-react'
import { TrackItemType } from '../../model/types/release'
import { useStyles } from './ReleaseTrackList.styles'

interface ReleaseTrackListProps {
    EditTrackButton: FC<{ uidTrack: string; children?: ReactNode }>
    DeleteTrackButton: FC<{ uidTrack: string }>
    trackList: TrackItemType[]
}

export const ReleaseTrackList: FC<ReleaseTrackListProps> = (props) => {
    const { EditTrackButton, DeleteTrackButton, trackList } = props

    const { classes } = useStyles()
    const { t } = useTranslation()
    const theme = useMantineTheme()

    const [hasScrolled, setHasScrolled] = useState<boolean>(false)
    const [previousTrackListLength, setPreviousTrackListLength] = useState<number>(0)

    useEffect(() => {
        // Если еще не прокручивали и длина списка увеличилась
        if (hasScrolled && trackList.length > previousTrackListLength) {
            window.scrollTo(0, document.body.scrollHeight)
        } else if (!hasScrolled) {
            setHasScrolled(true)
        }
        // Обновляем предыдущую длину списка треков
        setPreviousTrackListLength(trackList.length)
    }, [trackList])

    return (
        <>
            <Title order={2} mb={10}>
                Треклист
            </Title>

            {trackList.map((track, index) => (
                <Box key={track.uid} className={classes.trackItem}>
                    <Group position="apart" noWrap align="stretch">
                        <Group noWrap align="center" py={10} spacing={6}>
                            <Text span>{index + 1}.</Text>
                            <Text span sx={{ wordBreak: 'break-word' }}>
                                <EditTrackButton uidTrack={track.uid}>
                                    {track.name === null || track.name === '' ? 'Черновик' : track.name}
                                </EditTrackButton>
                            </Text>
                        </Group>
                        <Group position="right" noWrap>
                            {index !== 0 && <DeleteTrackButton uidTrack={track.uid} />}
                            {<EditTrackButton uidTrack={track.uid} />}
                            <Box
                                className={classes.rightSection}
                                sx={{
                                    color: track.file_exists ? theme.colors.green[8] : theme.colors.red[8],
                                    background: track.file_exists ? theme.colors.green[1] : theme.colors.red[1],
                                }}
                            >
                                <Tooltip
                                    label={track.file_exists ? 'Трек готов' : 'Заполните информация и загрузите трек'}
                                >
                                    {track.file_exists ? (
                                        <IconSquareRoundedCheckFilled color="green" />
                                    ) : (
                                        <IconSquareXFilled color="red" />
                                    )}
                                </Tooltip>
                            </Box>
                        </Group>
                    </Group>
                </Box>
            ))}
        </>
    )
}
