import classNames from 'classnames'
import { FC } from 'react'
import { useStyles } from './UserSidebarCard.styles'
import { UserNameEmailCard } from '../UserNameEmailCard/UserNameEmailCard'
import { UserAvatar } from '../UserAvatar/UserAvatar'

interface UserSidebarCardProps {
    className?: string
}

export const UserSidebarCard: FC<UserSidebarCardProps> = (props) => {
    const { className } = props

    const { classes } = useStyles()
    return (
        <div className={classNames(classes.userSidebarCard, {}, [className])}>
            <UserAvatar className={classes.ava} />
            <UserNameEmailCard />
        </div>
    )
}
