import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { updateAva } from 'features/changeUserInfo/services/updateAva/updateAva'
import { UserStateScheme } from '../types/user'

const initialState: UserStateScheme = {
    updateUserAva: {
        updateError: '',
        updateLoading: false,
    },
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(updateAva.pending, (state) => {
                state.updateUserAva.updateLoading = true
                state.updateUserAva.updateError = ''
            })
            .addCase(updateAva.fulfilled, (state) => {
                state.updateUserAva.updateLoading = false
            })
            .addCase(updateAva.rejected, (state, action) => {
                state.updateUserAva.updateError = action.error.message
                state.updateUserAva.updateLoading = false
            })
    },
})

export const { actions: userActions } = userSlice
export const { reducer: userReducer } = userSlice
