import classNames from 'classnames'
import { FC } from 'react'
import { Skeleton, Text } from '@mantine/core'
import { useStyles } from './UserNameEmailCard.styles'
import { useGetUserInfo } from '../../api/userApi'

interface UserNameEmailCardProps {
    className?: string
}

export const UserNameEmailCard: FC<UserNameEmailCardProps> = (props) => {
    const { className } = props
    const { classes } = useStyles()

    const { data, isLoading } = useGetUserInfo()

    return (
        <div className={classNames(classes.userNameEmailCard, {}, [className])}>
            <Text className={classes.name}>
                {isLoading ? <Skeleton h={15} width="100px" /> : data?.result.display_name}
            </Text>
            <Text className={classes.email}>
                {isLoading ? <Skeleton h={15} width="100px" mt="md" /> : data?.result.email}
            </Text>
        </div>
    )
}
