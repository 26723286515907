import classNames from 'classnames'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { UserSidebarCard } from 'entities/User'
import { MenuList } from 'widgets/Menu'
import { Text } from '@mantine/core'
import { useStyles } from './Sidebar.styles'
import { mainMenuItemsList, secondaryMenuItemsList } from '../../../shared/configs/menuConfig/menuConfig'

interface SidebarProps {
    className?: string
}

export const Sidebar: FC<SidebarProps> = (props) => {
    const { className } = props

    const { classes } = useStyles()
    const { t } = useTranslation()
    return (
        <div className={classNames(classes.sidebar, {}, [className])}>
            <UserSidebarCard />
            <MenuList className={classes.mainMenu} dataMenuItems={mainMenuItemsList} />
            <Text className={classes.secondaryTitle}>FAQ</Text>
            <MenuList className={classes.mainMenu} dataMenuItems={secondaryMenuItemsList} />
        </div>
    )
}
