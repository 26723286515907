import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { updateChartCountry } from 'features/financeChoiceDate/services/updateChartCountry/updateChartCountry'
import { updateChartStore } from 'features/financeChoiceDate/services/updateChartStore/updateChartStore'
import { updateChartMonth } from 'features/financeChoiceDate/services/updateChartMonth/updateChartMonth'
import { FinanceStateScheme } from '../types/finance'

const initialState: FinanceStateScheme = {
    startCalendar: '',
    endCalendar: '',
    chartCountryData: [],
    chartCountryLoading: false,
    chartCountryError: '',
    chartMonthData: { categories: [], total_sum: 0, series: [{ name: '', data: [], type: 'column' }] },
    chartMonthLoading: false,
    chartMonthError: '',
    chartStoreData: [],
    chartStoreLoading: false,
    chartStoreError: '',
}

export const financeSlice = createSlice({
    name: 'finance',
    initialState,
    reducers: {
        reducerStartCalendar: (state, action: PayloadAction<string>) => {
            state.startCalendar = action.payload
        },
        reducerEndCalendar: (state, action: PayloadAction<string>) => {
            state.endCalendar = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(updateChartCountry.pending, (state, action) => {
                state.chartCountryLoading = true
                state.chartCountryError = ''
            })
            .addCase(updateChartCountry.fulfilled, (state, action: PayloadAction<any>) => {
                state.chartCountryLoading = false
                state.chartCountryData = action.payload
            })
            .addCase(updateChartCountry.rejected, (state, action) => {
                state.chartCountryError = action.error.message
                state.chartCountryLoading = false
            })
            .addCase(updateChartStore.pending, (state) => {
                state.chartStoreLoading = true
                state.chartStoreError = ''
            })
            .addCase(updateChartStore.fulfilled, (state, action: PayloadAction<any>) => {
                state.chartStoreLoading = false
                state.chartStoreData = action.payload
            })
            .addCase(updateChartStore.rejected, (state, action) => {
                state.chartStoreError = action.error.message
                state.chartStoreLoading = false
            })
            .addCase(updateChartMonth.pending, (state) => {
                state.chartMonthLoading = true
                state.chartMonthError = ''
            })
            .addCase(updateChartMonth.fulfilled, (state, action: PayloadAction<any>) => {
                state.chartMonthLoading = false
                state.chartMonthData = action.payload
            })
            .addCase(updateChartMonth.rejected, (state, action) => {
                state.chartMonthError = action.error.message || ''
                state.chartMonthLoading = false
            })
    },
})

export const { actions: financeActions } = financeSlice
export const { reducer: financeReducer } = financeSlice
