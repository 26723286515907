import classNames from 'classnames'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TextDescriptionRight } from 'shared/ui/TextDescriptionRight/TextDescriptionRight'
import { Box, Group, Loader, Stack, Text, UnstyledButton } from '@mantine/core'
import { IconChevronUp, IconChevronDown } from '@tabler/icons-react'
import { useDisclosure } from '@mantine/hooks'
import { ArgDescriptionRight } from 'shared/ui/ArgDescriptionRight/ArgDescriptionRight'
import { useStyles } from './TrackItemDetailRelease.styles'
import { useLazyGetInfoTrack } from '../../api/releaseApi'

interface TrackItemDetailReleaseProps {
    trackUid: string
    title: string
    index: number
}

export const TrackItemDetailRelease: FC<TrackItemDetailReleaseProps> = (props) => {
    const { index, trackUid, title } = props

    const { classes } = useStyles()
    const { t } = useTranslation()

    const [activity, { open, close }] = useDisclosure()
    const [error, setError] = useState<string>('')
    const [getInfoTrack, { data, isLoading }] = useLazyGetInfoTrack()

    const handleInfoTrack = async () => {
        try {
            await getInfoTrack({ uid: trackUid }).unwrap()
        } catch (e) {
            setError('Произошла ошибка загрузки трека, попробуйте переоткрыть вкладку')
        }

        open()
    }

    return (
        <Box className={classes.trackItemDetailRelease}>
            <UnstyledButton className={classes.titleBtn} onClick={!activity ? handleInfoTrack : close}>
                <Group position="apart" className={classNames(classes.track, { [classes.trackActivy]: activity })}>
                    <Box>
                        {index + 1}. {title}
                    </Box>
                    <Box>
                        {isLoading && <Loader size={24} mr={10} />}
                        {activity ? <IconChevronUp /> : <IconChevronDown />}
                    </Box>
                </Group>
            </UnstyledButton>
            <Box className={classNames(classes.content, { [classes.contentActivity]: activity })}>
                {error !== '' ? (
                    <Text ta="center" py={15} color="red">
                        {error}
                    </Text>
                ) : (
                    <Stack py={15}>
                        <TextDescriptionRight leftText="Исполнитель:" rightText={data?.result.singers} />
                        <ArgDescriptionRight leftText="ФИО композитор:" dataDouble={data?.result.composer} />
                        {data?.result.arranger &&
                            data.result.arranger[0][0] !== null &&
                            data.result.arranger[0][0] !== '' && (
                                <ArgDescriptionRight leftText="ФИО аранжировщик:" dataDouble={data?.result.arranger} />
                            )}
                        <ArgDescriptionRight leftText="ФИО автора текста:" dataDouble={data?.result.authors} />
                        <TextDescriptionRight leftText="Язык песни:" rightText={data?.result.lang} />
                        <TextDescriptionRight leftText="Жанр:" rightText={data?.result.genre} />
                        {/* <TextDescriptionRight leftText="Поджанр:" rightText={''} /> */}
                        <TextDescriptionRight leftText="Год записи:" rightText={data?.result.track_year} />
                        {data?.result.remix_author !== '' && data?.result.remix_author !== null && (
                            <TextDescriptionRight
                                leftText="Имя автора ремикса:"
                                rightText={data?.result.remix_author}
                            />
                        )}
                        {data?.result.insta_index !== null && (
                            <TextDescriptionRight
                                leftText="Начало трека для TikTok / Instagram:"
                                rightText={data?.result.insta_index.toString() ?? ''}
                            />
                        )}
                        <TextDescriptionRight
                            leftText="Основная песня альбома:"
                            rightText={data?.result.main_song ? 'Да' : 'Нет'}
                        />
                        <TextDescriptionRight
                            leftText="Нецензурная лексика:"
                            rightText={data?.result.filthy_lang ? 'Да' : 'Нет'}
                        />
                        <TextDescriptionRight
                            leftText="Инструментальный трек:"
                            rightText={data?.result.is_instrumental ? 'Да' : 'Нет'}
                        />
                    </Stack>
                )}
            </Box>
        </Box>
    )
}
