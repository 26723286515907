import classNames from 'classnames'
import { FC } from 'react'
import { AppLink } from 'shared/ui/AppLink/AppLink'
import { useMatch } from 'react-router-dom'
import { useStyles } from './MenuItem.styles'
import { MenuItemType } from '../../model/types/menu'

interface MenuItemProps {
    className?: string
    item: MenuItemType
}

export const MenuItem: FC<MenuItemProps> = (props) => {
    const { className, item } = props

    const { classes } = useStyles()

    const isActive = window.location.href === item.path

    return (
        <AppLink className={classNames(classes.menuItem, { [classes.active]: isActive }, [className])} to={item.path}>
            <item.Icon
                className={classNames(classes.icon, {
                    [classes.activeIcon]: isActive,
                })}
            />
            {item.text}
        </AppLink>
    )
}
