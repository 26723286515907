import classNames from 'classnames'
import { FC, memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from '@mantine/form'
import { Checkbox, Stack, TextInput, Text, Button, Anchor } from '@mantine/core'
import { VerificationUser } from '../../model/types/verificationUser'
import { useStyles } from './VerificationForm.styles'
import { useSetVerificationUser } from '../../api/verificationUserApi'

interface VerificationFormProps {
    onClose: () => void
}

export const VerificationForm: FC<VerificationFormProps> = memo((props) => {
    const { onClose } = props

    const { classes } = useStyles()
    const { t } = useTranslation()

    const [setVerificationUser, { isLoading }] = useSetVerificationUser()

    const form = useForm<VerificationUser>({
        initialValues: {
            first_name: '',
            last_name: '',
            surname: '',
            citizenship: '',
            address: '',
            phone: '',
            telegram: '',
            terms_of_service_1: false,
            terms_of_service_2: false,
            terms_of_service_3: false,
            terms_of_service_4: false,
        },

        validate: (values) => ({
            first_name: values.first_name.length < 2 ? 'Слишком короткое имя' : null,
            last_name: values.last_name.length < 2 ? 'Слишком короткая фамилия' : null,
            surname: values.surname.length < 2 ? 'Слишком короткое отчество' : null,
            citizenship: values.citizenship.length < 1 ? 'Заполните гражданство' : null,
            address: values.address.length < 1 ? 'Заполните адрес' : null,
            phone: /^[+]?[\d]+$/.test(values.phone) ? null : 'Неправильный номер',
            telegram: /^[+]?[\d]+$/.test(values.telegram) ? null : 'Неправильный номер',
            terms_of_service_1: values.terms_of_service_1 === true ? null : 'Подтвердите правила пользования',
            terms_of_service_2: values.terms_of_service_2 === true ? null : 'Подтвердите правила пользования',
            terms_of_service_3: values.terms_of_service_3 === true ? null : 'Подтвердите правила пользования',
            terms_of_service_4: values.terms_of_service_4 === true ? null : 'Подтвердите правила пользования',
        }),
    })

    const handleSendForm = async (values: VerificationUser) => {
        try {
            await setVerificationUser(values).unwrap()
            onClose()
        } catch (e) {}
    }

    return (
        <div className={classNames(classes.verificationForm)}>
            <form onSubmit={form.onSubmit((values) => handleSendForm(values))}>
                <Stack py={30} spacing="lg">
                    <Text ta="justify">
                        Внимательно заполните информацию о владельце аккаунта. В целях безопастности все будущие выплаты
                        роялти будут производиться только владельцу аккаунта. Выплаты на карты/счета иных лиц будут
                        отклонены.
                    </Text>
                    <TextInput
                        withAsterisk
                        label="Имя"
                        placeholder="Введите Ваше имя"
                        {...form.getInputProps('first_name')}
                    />
                    <TextInput
                        withAsterisk
                        label="Фамилия"
                        placeholder="Введите Вашу фамилию"
                        {...form.getInputProps('last_name')}
                    />
                    <TextInput
                        withAsterisk
                        label="Отчество"
                        placeholder="Введите Ваше отчество"
                        {...form.getInputProps('surname')}
                    />
                    <TextInput
                        withAsterisk
                        label="Гражданство"
                        placeholder="Введите Ваше гражданство"
                        {...form.getInputProps('citizenship')}
                    />
                    <TextInput
                        withAsterisk
                        label="Адрес регистрации / фактического пребывания:"
                        placeholder="Введите Ваш адрес"
                        {...form.getInputProps('address')}
                    />
                    <TextInput
                        withAsterisk
                        label="Номер телефона"
                        placeholder="Введите Ваш номер телефона"
                        {...form.getInputProps('phone')}
                    />
                    <TextInput
                        withAsterisk
                        label="Дополнительный канал связи (Telegram, WhatsAPP)"
                        placeholder="Введите номер телефона связанный с (Telegram, WhatsAPP)"
                        {...form.getInputProps('telegram')}
                    />
                    <Checkbox
                        radius="xs"
                        label="Я гарантирую, что являюсь уполномоченным лицом на предоставление Контента третьим лицам, в том числе и Дистрибьютору"
                        {...form.getInputProps('terms_of_service_1', { type: 'checkbox' })}
                    />
                    <Checkbox
                        radius="xs"
                        label="Я гарантирую, что использование предоставленного мною Контента и прав на него не нарушает права третьих лиц"
                        {...form.getInputProps('terms_of_service_2', { type: 'checkbox' })}
                    />
                    <Checkbox
                        radius="xs"
                        // eslint-disable-next-line max-len
                        label="Я осознаю, что Сервис вправе удалить предоставленный мною Контент, если будут все основания полагать, что использование такого Контента нарушает или будет нарушать права третьих лиц, а также приостановить монетизацию и выплату вознаграждения"
                        {...form.getInputProps('terms_of_service_3', { type: 'checkbox' })}
                    />
                    <Checkbox
                        radius="xs"
                        label={
                            <>
                                Согласен с условиями{' '}
                                <Anchor href="https://yourtunes.net/usloviya-oferty" target="_blank">
                                    оферты
                                </Anchor>
                            </>
                        }
                        {...form.getInputProps('terms_of_service_4', { type: 'checkbox' })}
                    />
                    <Button fullWidth type="submit" color="dark" loading={isLoading}>
                        Отправить
                    </Button>
                </Stack>
            </form>
        </div>
    )
})
