import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Checkbox, Skeleton, Text } from '@mantine/core'
import { useAppDispatch } from 'shared/hooks/useAppDispatch/useAppDispatch'
import { useSelector } from 'react-redux'
import { paymentActions } from 'features/paymentGateway/model/slices/paymentSlice'
import { getPaymentPayFromBalance } from 'features/paymentGateway/model/selectors/getPaymentPayFromBalance'
import { getPaymentPrice } from 'features/paymentGateway/model/selectors/getPaymentPrice'
import { useStyles } from './BalancePaymentOption.styles'
import { useGetBalanceInfo } from '../api/paymentFromBalanceApi'

interface BalancePaymentOptionProps {}

export const BalancePaymentOption: FC<BalancePaymentOptionProps> = (props) => {
    const {} = props

    const { classes } = useStyles()
    const { t } = useTranslation()

    const dispatch = useAppDispatch()

    const payFromBalance = useSelector(getPaymentPayFromBalance)
    const price = useSelector(getPaymentPrice)

    const { data: balanceData, isLoading: isLoadingGetBalance } = useGetBalanceInfo()

    const handlePayFromBalance = (value: boolean) => {
        dispatch(paymentActions.reducerPayFromBalance(value))
    }

    useEffect(() => {
        if (balanceData) dispatch(paymentActions.reducerCurrentBalance(balanceData.result.balance))
    }, [balanceData])

    if (isLoadingGetBalance) {
        return <Skeleton h={40} width={'60%'} />
    }

    if (balanceData) {
        return (
            <>
                {balanceData.result.balance > 0 && (
                    <>
                        <Checkbox
                            label={`На балансе ${balanceData.result.balance_print} руб. Оплатить с баланса?`}
                            radius={'xs'}
                            checked={payFromBalance}
                            onChange={(event) => handlePayFromBalance(event.currentTarget.checked)}
                        />
                        {payFromBalance && (
                            <Text color="gray" fz={'sm'}>
                                {Number(price.name) <= Math.floor(balanceData?.result.balance)
                                    ? // eslint-disable-next-line max-len
                                      `Подписка будет оплачена полностью с Вашего баланса, спишется ${price.name} руб.`
                                    : // eslint-disable-next-line max-len
                                      `У Вас на балансе не хватает средств для оплаты подписки, но Вы можете оплатить часть с баланса ${balanceData
                                          ?.result.balance} руб. А оставшееся оплатите с карты ${
                                          Number(price.name) - balanceData?.result.balance
                                      } руб.`}
                            </Text>
                        )}
                    </>
                )}
            </>
        )
    }

    return null
}
