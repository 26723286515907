import classNames from 'classnames'
import { FC, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { useMediaQuery } from '@mantine/hooks'
import { truncateText } from 'shared/tools/truncateText'
import { formatNumber } from 'shared/tools/formatNumber'
import { useSelector } from 'react-redux'
import { getChartStoreData } from 'entities/Finance/model/selectors/getChartStoreData/getChartStoreData'
import { Center, Title } from '@mantine/core'
import { BorderWindow } from 'shared/ui/BorderWindow/BorderWindow'
import { FinanceStoreSeries } from 'entities/Finance/model/types/finance'
import { useStyles } from './FinanceStore.styles'

interface FinanceStoreProps {
    className?: string
}

export const FinanceStore: FC<FinanceStoreProps> = (props) => {
    const { className } = props

    const { classes } = useStyles()
    const { t } = useTranslation()

    const md = useMediaQuery('(max-width: 992px)')
    const xll = useMediaQuery('(max-width: 1600px)')

    const chartStoreData = useSelector(getChartStoreData)

    const [chartDATA, setChartDATA] = useState<FinanceStoreSeries[]>([])

    useEffect(() => {
        if (chartStoreData !== undefined) {
            const sortedData = chartStoreData
                .map((item) => {
                    return { ...item }
                })
                .sort((a, b) => b.y - a.y)
            setChartDATA(sortedData)
        }
    }, [chartStoreData])

    const options: Highcharts.Options = {
        chart: {
            type: 'pie',
            plotBackgroundColor: '#f8f9fa',
            backgroundColor: '#f8f9fa',
            height: xll ? 500 : 400,
        },
        title: {
            text: undefined,
        },

        accessibility: {
            announceNewData: {
                enabled: true,
            },
        },
        credits: {
            enabled: false,
        },

        plotOptions: {
            pie: {
                innerSize: '80%',
                center: ['50%', '50%'],
            },
            series: {
                // borderRadius: 5,
                dataLabels: {
                    enabled: false,
                },
                showInLegend: true,
            },
        },
        legend: {
            align: xll ? 'center' : 'right',
            verticalAlign: xll ? 'bottom' : 'middle',
            layout: 'vertical',
            itemStyle: {
                fontSize: '15px',
            },
            maxHeight: 200,
            labelFormatter: function () {
                let point = this as Highcharts.Point
                let name = point.name
                return truncateText(name, 27) + ': ' + formatNumber(point.y) + ' ₽'
            },
        },
        tooltip: {
            headerFormat: undefined,
            pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b> ₽<br/>',
        },
        series: [{ name: 'Brands', data: chartDATA, type: 'pie' }],
    }

    return (
        <BorderWindow>
            <Title order={2} mb={40}>
                Выручка по магазинам
            </Title>
            <>
                {chartStoreData?.length === 0 ? (
                    <Center fw={600} my={50}>
                        За текущий период график пуст
                    </Center>
                ) : (
                    <HighchartsReact highcharts={Highcharts} options={options} />
                )}
            </>
        </BorderWindow>
    )
}
