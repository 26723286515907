import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Title, Box, LoadingOverlay, Center } from '@mantine/core'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { BorderWindow } from 'shared/ui/BorderWindow/BorderWindow'
import { EmptyStatList } from 'shared/ui/EmptyStatList/EmptyStatList'
import { useSelector } from 'react-redux'
import { formatNumber } from 'shared/tools/formatNumber'
import { truncateText } from 'shared/tools/truncateText'
import { useResponsive } from 'shared/hooks/useResponsive/useResponsive'
import { getStatisticsLoading } from '../../model/selectors/getStatisticsLoading/getStatisticsLoading'
import { useStyles } from './StatChartReleasesStore.styles'
import { getChartReleaseStore } from '../../model/selectors/getChartReleaseStore/getChartReleaseStore'

interface StatChartReleasesStoreProps {
    className?: string
}

export const StatChartReleasesStore: FC<StatChartReleasesStoreProps> = (props) => {
    const { className } = props

    const { classes } = useStyles()
    const { t } = useTranslation()

    const chartLoading = useSelector(getStatisticsLoading)
    const chartData = useSelector(getChartReleaseStore)

    const { sm, md, lg } = useResponsive()

    const [options, setOptions] = useState<Highcharts.Options>({
        chart: {
            type: 'pie',
            plotBackgroundColor: '#f8f9fa',
            backgroundColor: '#f8f9fa',
        },
        title: {
            text: undefined,
        },

        accessibility: {
            announceNewData: {
                enabled: true,
            },
        },
        credits: {
            enabled: false,
        },

        plotOptions: {
            pie: {
                innerSize: '80%',
                center: ['50%', '50%'],
            },
            series: {
                // borderRadius: 5,
                dataLabels: {
                    enabled: false,
                },
                showInLegend: true,
            },
        },
        legend: {
            align: 'right',
            verticalAlign: 'middle',
            layout: 'vertical',
            itemStyle: {
                fontSize: '15px',
            },
            maxHeight: 200,
            labelFormatter: function () {
                let point = this as Highcharts.Point
                let name = point.name
                return truncateText(name, 27) + ': ' + formatNumber(point.y) + ' ₽'
            },
        },
        tooltip: {
            headerFormat: undefined,
            pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b> ₽<br/>',
        },
        series: [],
    })

    useEffect(() => {
        if (chartData !== undefined) {
            const newData: any = chartData.map((el) => ({
                name: el.platform,
                y: Number(el.stream),
            }))

            setOptions((prevOptions) => ({
                ...prevOptions,
                series: [
                    {
                        data: newData,
                        type: 'pie',
                        name: 'Прослушивания',
                    },
                ],
            }))
        }
    }, [chartData])

    useEffect(() => {
        setOptions((prevOptions) => ({
            ...prevOptions,
            chart: {
                ...prevOptions.chart,
                width: sm ? 300 : 600,
            },
            legend: {
                ...prevOptions.legend,
                align: sm ? 'center' : 'right',
                verticalAlign: sm ? 'bottom' : 'middle',
            },
        }))
    }, [sm])

    return (
        <BorderWindow>
            <Title order={2}>Платформы</Title>
            <Box pos={'relative'} mih={250}>
                <LoadingOverlay visible={chartLoading} />
                {chartData.length === 0 ? (
                    <EmptyStatList />
                ) : (
                    <Center>
                        <Box>
                            <HighchartsReact highcharts={Highcharts} options={options} />
                        </Box>
                    </Center>
                )}
            </Box>
        </BorderWindow>
    )
}
