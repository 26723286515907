import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { SelectTariffStateScheme } from '../types/selectTariff'

const initialState: SelectTariffStateScheme = {
    IsTariffModalOpen: false,
    message: '',
    selectedActivityTariff: { startIndex: 0, endIndex: 3 },
}

export const selectTariffSlice = createSlice({
    name: 'selectTariff',
    initialState,
    reducers: {
        reducerTariffModal: (
            state,
            action: PayloadAction<{ isOpen: boolean; message?: string; startIndex: number }>,
        ) => {
            if (action.payload.message) {
                state.message = action.payload.message
            } else if (!action.payload.message) {
                state.message = ''
            }
            state.IsTariffModalOpen = action.payload.isOpen
            state.selectedActivityTariff.startIndex = action.payload.startIndex
        },
        reducerTariffMessage: (state, action: PayloadAction<string>) => {
            state.message = action.payload
        },
        // reducerSelectActivityTariff: (state, action: PayloadAction<{ startIndex: number; endIndex: 3 }>) => {
        //     state.selectedActivityTariff = action.payload
        // },

        // reducerCloseTariffAndOpenPaymentModal: (state, action: PayloadAction<false>) => {
        //     state.IsTariffModalOpen = action.payload
        // },
    },
    extraReducers: () => {},
})

export const { actions: selectTariffActions } = selectTariffSlice
export const { reducer: selectTariffReducer } = selectTariffSlice
