import classNames from 'classnames'
import { FC } from 'react'
import { IconCreditCard, IconTrash } from '@tabler/icons-react'
import { Popover, Button, Text } from '@mantine/core'
import { useDeleteBankCard } from 'features/fastPaymentCards/api/fastPaymentCardsApi'
import { useStyles } from './BankCard.styles'

interface BankCardProps {
    guid: string | null
    cardNumber: string
    selectedValue: string
    handleSetCardUid: (newVal: string) => void
}

export const BankCard: FC<BankCardProps> = (props) => {
    const { guid, cardNumber, selectedValue, handleSetCardUid } = props

    const { classes } = useStyles()

    const [deleteBankCard, { isLoading }] = useDeleteBankCard()

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        handleSetCardUid(event.target.value)
    }

    const handleDelete = async () => {
        try {
            await deleteBankCard({ guid: guid ?? '' }).unwrap()
        } catch (e) {}
    }

    const isChecked = guid === selectedValue

    return (
        <div className={classes.bankCard}>
            <div className={classNames(classes.contentCard, { [classes.cheked]: isChecked })}>
                <input
                    type="radio"
                    value={guid ?? ''}
                    name="bankCard"
                    className={classes.input}
                    checked={isChecked}
                    onChange={handleInputChange}
                />
                <span className={classes.text}>{cardNumber}</span>
                {guid !== '' && <IconCreditCard stroke={1.3} size={20} color={isChecked ? 'white' : 'black'} />}
                {isChecked && guid !== '' && (
                    <div className={classes.buttonWrapper}>
                        <Popover position="top">
                            <Popover.Target>
                                <Button compact color="dark" variant="light" px={5} py={0}>
                                    <IconTrash size={16} />
                                </Button>
                            </Popover.Target>
                            <Popover.Dropdown p="0">
                                <Button variant="filled" color="red" compact loading={isLoading} onClick={handleDelete}>
                                    <Text fz="xs" fw={400}>
                                        Удалить карту
                                    </Text>
                                </Button>
                            </Popover.Dropdown>
                        </Popover>
                    </div>
                )}
            </div>
        </div>
    )
}
