import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Stack, Title, Image, Box, Group, Button, Divider, Alert, List, Text, Skeleton } from '@mantine/core'
import { TextDescriptionRight } from 'shared/ui/TextDescriptionRight/TextDescriptionRight'
import { ArgDescriptionRight } from 'shared/ui/ArgDescriptionRight/ArgDescriptionRight'
import dayjs from 'dayjs'
import { IconExclamationCircle, IconCircleX } from '@tabler/icons-react'
import { useCurrentReleaseUid } from 'entities/Release/hooks/useCurrentReleaseUid'
import { useResponsive } from 'shared/hooks/useResponsive/useResponsive'
import { useGetImageRelease, useGetInfoRelease, useGetTrackList } from '../../api/releaseApi'
import { useStyles } from './ReleaseDetail.styles'
import { TrackItemDetailRelease } from '../TrackItemDetailRelease/TrackItemDetailRelease'
import { releaseType } from '../../model/tools/releaseType'

interface ReleaseDetailProps {
    AddingReleaseFinish?: FC<{ publicDate: string }>
    finishedStep: boolean
}

export const ReleaseDetail: FC<ReleaseDetailProps> = (props) => {
    const { AddingReleaseFinish, finishedStep } = props

    const { classes } = useStyles()
    const { t } = useTranslation()
    const { xs, sm } = useResponsive()

    const currentReleaseUID = useCurrentReleaseUid()

    const { data: releaseInfoData, isLoading: isLoadingInfo } = useGetInfoRelease({ uid: currentReleaseUID })
    const { data: releaseImageData, isLoading: isLoadingImage } = useGetImageRelease({ uid: currentReleaseUID })
    const { data: trackListData, isLoading: isLoadingTrackList } = useGetTrackList({ uid: currentReleaseUID })

    if (isLoadingInfo || isLoadingImage || isLoadingTrackList) {
        return (
            <Stack>
                <Skeleton width={'100%'} height={60} />
                <Group position="apart">
                    <Skeleton width={364} height={364} />
                    <Skeleton width={'50%'} height={364} />
                </Group>
                <Skeleton width={'100%'} height={60} />
                <Skeleton width={'100%'} height={60} />
            </Stack>
        )
    }

    if (releaseInfoData && releaseImageData && trackListData) {
        const renderRejected = () => {
            return (
                <Alert
                    title={
                        <Text fw={600} fz="lg">
                            Ваш релиз отклонён!
                        </Text>
                    }
                    color="red"
                    variant="filled"
                >
                    <Text color="white" fw={600} my={15} fz="md">
                        {'Статус "Отклонён" указывает на то, что наш сервис не может ' +
                            ' произвести публикацию вашего релиза по следующим причинам:'}
                    </Text>
                    <List spacing={10}>
                        {releaseInfoData.result.errors &&
                            releaseInfoData.result.errors.map((error, index) => (
                                <List.Item icon={<IconCircleX color="white" size={26} />} key={index}>
                                    <Text color="white">{error}</Text>
                                </List.Item>
                            ))}
                    </List>
                </Alert>
            )
        }

        const renderError = () => {
            if (releaseInfoData.result.errors) {
                return (
                    <>
                        {releaseInfoData.result.errors.length > 0 && (
                            <Alert title="Исправьте ошибки:" color="red" variant="outline">
                                <List spacing={10}>
                                    {releaseInfoData.result.errors.map((error, index) => (
                                        <List.Item
                                            icon={<IconExclamationCircle color="red" stroke={1} size={24} />}
                                            key={index}
                                        >
                                            {error}
                                        </List.Item>
                                    ))}
                                </List>
                            </Alert>
                        )}
                        <Text color="gray" ta={'justify'} mt={30} fz="sm">
                            Заметка: После того, как вы исправите обнаруженные ошибки, модератору потребуется некоторое
                            время для повторной проверки. Пожалуйста, отслеживайте статус ошибки.
                        </Text>
                    </>
                )
            }
        }

        const renderReleaseInfo = () => {
            return (
                <Stack pl={10}>
                    <TextDescriptionRight leftText="Название релиза" rightText={releaseInfoData.result.name} />
                    <ArgDescriptionRight leftText="Основной исполнитель:" data={releaseInfoData.result.main_singers} />
                    {releaseInfoData.result.other_singers[0] !== '' && (
                        <ArgDescriptionRight leftText="При участии:" data={releaseInfoData?.result.other_singers} />
                    )}
                    <TextDescriptionRight
                        leftText="Дата релиза:"
                        rightText={dayjs(releaseInfoData.result.public_date).format('DD.MM.YYYY')}
                    />
                    <TextDescriptionRight
                        leftText="Тип релиза:"
                        rightText={releaseInfoData.result.type && releaseType[releaseInfoData.result.type]}
                    />
                    <TextDescriptionRight leftText="Год записи:" rightText={releaseInfoData.result.album_year} />
                    <TextDescriptionRight leftText="Жанр:" rightText={releaseInfoData.result.genre} />
                    {/* <TextDescriptionRight leftText="Поджанр:" rightText={'ssss'} /> */}
                    {releaseInfoData.result.version !== '' && releaseInfoData.result.version !== null && (
                        <TextDescriptionRight
                            leftText="Версия/Подзаголовок:"
                            rightText={releaseInfoData.result.version}
                        />
                    )}
                    <TextDescriptionRight leftText="Авторское право:" rightText={releaseInfoData.result.copyright} />
                    <TextDescriptionRight
                        leftText="Разные исполнители / Сборник:"
                        rightText={releaseInfoData.result.different_singers ? 'Да' : 'Нет'}
                    />
                </Stack>
            )
        }

        const renderImage = () => {
            return (
                <>
                    <Box>
                        <Image
                            src={releaseImageData.result.ava_link}
                            alt=""
                            radius="md"
                            withPlaceholder
                            className={classes.image}
                        />
                    </Box>
                </>
            )
        }

        return (
            <>
                {AddingReleaseFinish && (
                    <Title order={2} mb={30}>
                        Ваш релиз готов к отправке на модерацию!
                    </Title>
                )}
                {finishedStep ? (
                    <Text color="gray">Проверьте корректность данных в релизе и нажмите кнопку «отправить»</Text>
                ) : (
                    <Title order={2}>Общая информация</Title>
                )}
                <Divider my={30} />
                <Grid mb={60}>
                    {!finishedStep && releaseInfoData.result.errors ? (
                        <>
                            <Grid.Col order={2} orderXl={1} span={12} xl={6}>
                                {renderImage()}
                                {renderReleaseInfo()}
                            </Grid.Col>
                            <Grid.Col order={1} orderXl={2} span={12} xl={6}>
                                {releaseInfoData.result.status === 8 && renderRejected()}
                                {releaseInfoData.result.status === 2 && renderError()}
                            </Grid.Col>
                        </>
                    ) : (
                        <>
                            <Grid.Col span={sm ? 12 : 'content'}>{renderImage()}</Grid.Col>
                            <Grid.Col span={sm ? 12 : 'auto'}>{renderReleaseInfo()}</Grid.Col>
                        </>
                    )}
                </Grid>
                {trackListData.result && trackListData.result.length > 0 && (
                    <Box maw={800}>
                        <Divider my={30} />
                        {trackListData.result.map((track, index) => (
                            <TrackItemDetailRelease
                                trackUid={track.uid}
                                key={track.uid}
                                title={track.name}
                                index={index}
                            />
                        ))}
                    </Box>
                )}
                <Box mt={50}>
                    {AddingReleaseFinish && releaseInfoData.result.public_date ? (
                        <AddingReleaseFinish publicDate={releaseInfoData.result.public_date} />
                    ) : (
                        <Group position="right">
                            <Button
                                variant="outline"
                                color="dark"
                                component="a"
                                href="https://yourtunes.net/relizy"
                                className={classes.button}
                                fullWidth
                            >
                                Вернуться к списку
                            </Button>
                        </Group>
                    )}
                </Box>
            </>
        )
    }

    return null
}
